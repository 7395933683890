import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { fetchApplications } from "../../../../../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../../../../../providers/GlobalProvider";
import { Gif } from "../../../../../../../../utils/mediaExports";

const theadings = [
  {
    title: "Sl No.",
    width: "50px",
  },
  {
    title: "Titan User ID",
    width: "",
  },
  {
    title: "Name of the Applicant",
    width: "",
  },
  {
    title: "Startup Name",
    width: "",
  },
  {
    title: "Titan Project ID",
    width: "",
  },
  {
    title: "Project Title",
    width: "",
  },
  {
    title: "Sector",
    width: "",
  },
];

const ScoreCardTable = ({ persons, call }) => {
  const dispatch = globalDispatch();
  const controller = new AbortController();

  const [applications, setApplications] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [applicationsCount, setApplicationsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };
  const onGetDataPageWise = async () => {
    setLoading(true);
    const response = await fetchApplications(
      {
        callId: call._id,
        phase: 1,
        pageNumber: pageNumber === 0 ? 1 : pageNumber + 1,
        pageSize: rowsPerPage,
      },
      dispatch,
      controller
    );

    if (response && response.status == 200) {
      setApplicationsCount(response?.msg?.totalApplications);
      setApplications(response.msg?.applications);
    }

    setLoading(false);
  };
  useEffect(() => {
    onGetDataPageWise();
  }, [pageNumber, rowsPerPage]);
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {theadings?.map((heading, i) => {
              return (
                <TableCell align="center" width={heading.width} key={i}>
                  {heading.title}
                </TableCell>
              );
            })}

            {persons?.map((person, i) => {
              return (
                <TableCell align="center" key={i}>
                  {person.abbr}
                </TableCell>
              );
            })}

            <TableCell align="center">Total</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={13}>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Gif.flying}
                    alt="loading"
                    style={{ width: "10rem" }}
                  />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            applications?.map((application, i) => {
              let total = 0;
              return (
                <TableRow key={i} className="pointer">
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell align="center">
                    {application?.userId ? application?.userId : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {application?.applicantName
                      ? application?.applicantName
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {application?.companyName}
                  </TableCell>
                  <TableCell align="center">
                    {application?.projectId ? application?.projectId : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {application?.projectTitle
                      ? application?.projectTitle
                      : "-"}
                  </TableCell>
                  <TableCell align="center">-</TableCell>
                  {persons?.map((person, i) => {
                    if (application?.applicationData?.phase2?.data) {
                      if (
                        application?.applicationData?.phase2?.data[person?.id]
                          ?.total
                      ) {
                        total +=
                          application?.applicationData?.phase2?.data[person?.id]
                            ?.total;
                      }
                    } else {
                      total = 0;
                    }
                    return (
                      <TableCell align="center" key={i}>
                        {application?.applicationData?.phase2?.data?.[
                          person?.id
                        ]?.total || 0}
                      </TableCell>
                    );
                  })}
                  <TableCell align="center">{total}</TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={pageNumber}
            count={applicationsCount || 0}
          />
        </TableRow>
      </Table>
    </TableContainer>
  );
};

export default ScoreCardTable;
