import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import CustomButton from "./CustomButton";
import styled from "styled-components";
import {
  fetchAllApplicationsData,
  fetchApplications,
} from "../../data/network/api/scheme";
import { useParams } from "react-router-dom";
import { useState } from "react";
const ExportScoreCardExcel = ({
  reviewersData,
  disabled,
  dispatch,
  controller,
  fileName,
}) => {
  const [downloadingReport, setDownloadingReport] = useState(false);
  const { id } = useParams();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const onCreateExcelData = (
    arr,
    phase2ReviewerData,
    index,
    application,
    name
  ) => {
    let data = {
      "Sl No.": index,
      "Titan User ID": application?.userId ? application?.userId : "-",
      "Name of the Applicant": application?.applicantName
        ? application?.applicantName
        : "-",
      "Startup Details": application?.companyName,
      "Titan Project ID": application?.projectId ? application?.projectId : "-",
      "Project Title": application?.projectTitle
        ? application?.projectTitle
        : "-",
      Relevance: phase2ReviewerData?.clarity || 0,
      Effectiveness: phase2ReviewerData?.unmetNeed || 0,
      Impact: phase2ReviewerData?.customerPay || 0,
      "Originality and Innovation": phase2ReviewerData?.novelty || 0,
      Presentation: phase2ReviewerData?.techFeasibility || 0,
      "Total Score": phase2ReviewerData?.total || 0,
      Recommend: phase2ReviewerData?.recommend
        ? "TRUE"
        : phase2ReviewerData?.recommend === null
        ? "-"
        : "FALSE",
      Comments: phase2ReviewerData?.comment || "-",
      "Reviewer Name": name,
    };
    arr.push(data);
  };
  const onAddExcelData = (
    applicationsData,
    reviewersData,
    excelData,
    excelData2
  ) => {
    let slNo = 0;
    let arr = [];
    const AllConsolidatedData = [];
    applicationsData?.map((application, index) => {
      let consolidatedData = {
        "sl No": index + 1,
        "Titan User ID": application?.userId ? application?.userId : "-",
        "Name of the Applicant": application?.applicantName
          ? application?.applicantName
          : "-",
        "Startup Name": application?.companyName,
        "Titan Project ID": application?.projectId
          ? application?.projectId
          : "-",
        "Project Title": application?.projectTitle
          ? application?.projectTitle
          : "-",

        Sector: "-",
      };
      let applicationTotal = 0;
      reviewersData?.map((Data, index) => {
        let data = "";
        if (
          application?.applicationData?.phase2?.data &&
          application?.applicationData?.phase2?.data[Data?.id]
        ) {
          data = application?.applicationData?.phase2?.data[Data?.id];
          slNo += 1;
          onCreateExcelData(arr, data, slNo, application, Data?.abbr);
        } else {
          data = {
            clarity: 0,
            comment: "",
            customerPay: 0,
            novelty: 0,
            recommend: null,
            teamStrength: 0,
            techFeasibility: 0,
            total: 0,
            unmetNeed: 0,
          };
        }
        consolidatedData[Data?.abbr] = data?.total;
        if (data?.total) {
          applicationTotal += data?.total;
        } else {
          applicationTotal += 0;
        }
      });

      AllConsolidatedData.push({
        ...consolidatedData,
        Total: applicationTotal,
      });
    });
    excelData2.push(...AllConsolidatedData);
    excelData.push(...arr);
  };
  const exportToExcel = async () => {
    setDownloadingReport(true);
    const response = await fetchApplications(
      {
        callId: id,
        phase: 1,
        isExport: true,
      },
      dispatch,
      controller
    );
    const excelData = [];
    const excelData2 = [];
    onAddExcelData(
      response?.msg?.applications,
      reviewersData,
      excelData,
      excelData2
    );
    const ws = XLSX.utils.json_to_sheet(excelData);

    const ws2 = XLSX.utils.json_to_sheet(excelData2);
    const wb = {
      Sheets: { data: ws, "Consolidated Data": ws2 },
      SheetNames: ["data", "Consolidated Data"],
    };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    setDownloadingReport(false);
  };
  return (
    <Container>
      <CustomButton
        title={downloadingReport ? "Excel Exporting..." : "Export ScoreCard"}
        onClickHandler={exportToExcel}
        disabled={disabled}
      />
    </Container>
  );
};

export default ExportScoreCardExcel;

const Container = styled.div``;
