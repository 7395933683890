import { useEffect, useState } from "react";
import styled from "styled-components";
import { applicationCount, fetchAllApplicationsData, getOnlineReviewApplicationsPrayas } from "../../../../../../../data/network/api/scheme";
import { globalDispatch, globalSelector } from "../../../../../../../providers/GlobalProvider";
import { PrayasDispatch } from "../../../../../../../providers/scheme/prayas/PrayasProvider";
import { updatePrayasNotification1 } from "../../../../../../../reducers/schemes/prayas/tabs";
import CustomButton from "../../../../../../customComponents/CustomButton";
import CustomTextSearch from "../../../../../../customComponents/CustomSearchText";
import { SchemePhaseTabContainer } from "../../../../big/phases/ikp/Applications";
import { tabs } from "../../Applications";
import FinalistsTable from "./widgets/FinalistsTable";
import PersonTable from "./widgets/PersonTable";
import ReviewTab from "./widgets/ReviewTab";
import ScoreCardTable from "./widgets/ScoreCardTable";

const OnlineReview = ({call}) => {

  const [activeReviewTab, setActiveReviewTab] = useState(0);
  const [applications, setApplications] = useState([]);

  const controller = new AbortController();
  const dispatch = globalDispatch();
  const prayasDispatch = PrayasDispatch();
  const user = globalSelector((state) => state.auth.user);
  const [reviewers, setReviewers] = useState([]);
  const [searchText, setSearchText] = useState("");
  
  useEffect(() => {
    getApplications();
  }, []);

  const getApplications = async () => {
    applicationCount({ callId: call._id }, dispatch, controller).then(
      (response) => {
        console.log(response);
        Object.keys(tabs).map((key, i) => {
          prayasDispatch(
            updatePrayasNotification1({
              id: tabs[key],
              value: response.msg?.[i] || 0,
            })
          );
        });
      }
    );
    const response = await fetchAllApplicationsData(
      { callId: call._id, phase: 2 },
      dispatch,
      controller
    );

    if (response && response.status == 200) {
      prayasDispatch(
        updatePrayasNotification1({
          id: "review",
          value: response.msg?.applications?.length || 0,
        })
      );
      setApplications(response.msg?.applications);
      let reviewers = new Set();
      response.msg?.programManagers.map((manager, i) => {
        return reviewers.add(JSON.stringify({_id: manager._id, name: manager.name}));
      })
      for (var i=0;i<response?.msg?.applications?.length;i++) {
        response?.msg?.applications[i]?.reviewer?.map((reviewer, i) => {
          
          reviewers.add(JSON.stringify(reviewer));
        })
      }
      const formattedSet = [...reviewers].map(item => {
        if (typeof item === 'string') return JSON.parse(item);
        else if (typeof item === 'object') return item;
      });
      console.log(reviewers, "revieweres");
      setReviewers(formattedSet);
    }
  
  }

  const ReviewTable = () => {
    if (activeReviewTab < reviewers.length) {
      return <PersonTable person={reviewers[activeReviewTab]} applications={applications} callId={call?._id} />;
    }
    switch (activeReviewTab) {
      case reviewers?.length:
        return <ScoreCardTable persons={reviewers} applications={applications} />;
      // case persons.length + 1:
      //   return <FinalistsTable />
    }
  };

  return (
    <SchemePhaseTabContainer>
      <div className="topRow">
        <CustomTextSearch
          value={searchText}
          setValue={(value) => setSearchText(value)}
          upcomingBanner={true}
        />
        <CustomButton
          title="Add New Applicant"
          onClickHandler={() => setOpen(true)}
        />
      </div>

      <ReviewTab
        person={reviewers}
        activeReviewTab={activeReviewTab}
        setActiveReviewTab={setActiveReviewTab}
      />

      <ReviewTabContainer>
        <ReviewTable />
      </ReviewTabContainer>
    </SchemePhaseTabContainer>
  );
};

export default OnlineReview;

const ReviewTabContainer = styled.div`
    margin-top: 1rem;
`;
