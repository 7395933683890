import {
  Button,
  Checkbox,
  Dialog,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  FormLabel,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  applicationCount,
  checkApplication,
  fetchApplications,
  getApplicationsByReviewer,
  getFilterersData,
  saveComment,
  applyFilteres,
} from "../../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../providers/GlobalProvider";
import { TitanDispatch } from "../../../../../providers/scheme/titan/TitanProvider";
import { updateTitanNotification1 } from "../../../../../reducers/schemes/titan/tabs";
import TitanSchemeStore from "../../../../../store/scheme/titan/titan";
import { ApplicationStatus } from "../../../../../utils/constants";
import { Gif, Icons } from "../../../../../utils/mediaExports";
import CustomButton from "../../../../customComponents/CustomButton";
import CustomTextSearch from "../../../../customComponents/CustomSearchText";
import ExcelFileUploadDialog from "../../../customComponents/modals/ExcelFileUploadDialog";
import TablePopUp from "../../../customComponents/modals/TablePopUp";
import InviteReviewerActionDialog from "./application/applicationAction/InviteReviewer";
import {
  ArrowDropDown,
  Filter,
  Filter1TwoTone,
  Filter3,
  FilterAlt,
} from "@mui/icons-material";
import ExportExcel from "../../../../customComponents/ExportExcel";
import TitanActionDialog from "./application/applicationAction/TitanActionModel";
import MultipleSelectCheckmarks from "../../../../customComponents/CustomMultiSelect";
import { DialogBody } from "../../../../../utils/customStyledComponents";
import StatusToTitanActionModel from "./application/applicationAction/StatusToTitanActionModel";
export const tabs = {
  0: "applications",
  1: "review",
  // 2: "pitchingSelection",
  // 3: "dueDeligence",
  // 4: "award",
  // 7: "agreementSigning",
  // 8: "milestones",
};

const Applications = ({ call, active, setActive }) => {
  const dispatch = globalDispatch();
  const user = globalSelector((state) => state.auth.user);
  const titanDispatch = TitanDispatch();
  const controller = new AbortController();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [isSelectAll, setIsselectAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [applications, setApplications] = useState([]);
  const [filterSelected, setFilter] = useState(0);
  const [filterApply, setFilterApply] = useState(false);
  const [focusArea, setFocusArea] = useState([]);
  const [subFocusArea, setSubFocusArea] = useState([]);
  const [sdg, setSdg] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedFocusArea, setSelectedFocusArea] = useState([]);
  const [selectedSubFocusArea, setSelectedSubFocusArea] = useState([]);
  const [selectedSdg, setSelectedSdg] = useState([]);
  const [filteredApplications, setFilterApplications] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [isTablePopOpen, setIsTablePopOPen] = useState(false);
  const [applicationsID, setApplicationsID] = useState([]);
  const [selectedApplicationID, setSelectedApplicationID] = useState("");
  const [selectedAction, setSelectedAction] = useState(-1);
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [reviewers, setReviewers] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [applicationsCount, setApplicationsCount] = useState(0);
  const [isFilteredApplied, setIsFilterApplied] = useState(false);
  const [isReviewerDataFound, setIsReviewerDataFound] = useState(false);
  const [
    isOpenInviteReviewerActionDialog,
    setIsOpenInviteReviewerActionDialog,
  ] = useState(false);
  const [isTitanTostatusDialogueOpen, setIsTitanToStatusDialogueOpen] =
    useState(false);
  const isProgramManager = useMemo(() => {
    const data = call?.scheme?.programManager?.filter(
      (data) => data?._id === user?._id
    );
    if (data?.length !== 0) {
      return true;
    } else {
      return false;
    }
  }, [call]);
  const modalHandler = (e, reason) => {
    if (reason == "backdropClick") return;
    setOpen(false);
  };

  const onUpdateTitanNotification1 = (response) => {
    Object.keys(tabs).map((key, i) => {
      titanDispatch(
        updateTitanNotification1({
          id: tabs[key],
          value: response?.msg?.totalApplications || 0,
        })
      );
    });
  };
  const getApplications = async () => {
    // applicationCount({ callId: call._id }, dispatch, controller).then(
    // 	(response) => {
    // Object.keys(tabs).map((key, i) => {
    // 	titanDispatch(
    // 		updateTitanNotification1({
    // 			id: tabs[key],
    // 			value: response?.msg?.totalApplications || 0,
    // 		})
    // 	);
    // });
    // 		setApplicationsCount(response?.msg[0]);
    // 	}
    // );
    let response = "";
    let response1 = "";
    if (user?.role.roleId === "external_reviewer") {
      response = await getApplicationsByReviewer(
        { callId: call._id },
        dispatch,
        controller
      );
    } else {
      let data;
      if (user?.role.roleId === "founder") {
        data = await getApplicationsByReviewer(
          { callId: call._id },
          dispatch,
          controller
        );
        if (data) {
          response = data;
          setIsReviewerDataFound(true);
        }
      }

      if (!data) {
        response = await fetchApplications(
          { callId: call._id, phase: 1, pageNumber: pageNumber + 1 },
          dispatch,
          controller
        );
        response1 = await getFilterersData(
          { callId: call._id },
          dispatch,
          controller
        );
      }
    }
    if (response1 && response1?.status == 200) {
      setFocusArea(
        response1?.msg?.focusArea?.map((data) => ({ abbr: data?.name }))
      );
      setSdg(response1?.msg?.sdg?.map((data) => ({ abbr: data })));
      setFilterData(response1?.msg?.focusArea);
      setFilterApply(true);
    }

    if (response && response.status == 200) {
      const applicationsIDArray = response?.msg?.applications.map(
        (application) => application?._id
      );
      onUpdateTitanNotification1(response);
      setApplicationsCount(response?.msg?.totalApplications);
      setApplicationsID(applicationsIDArray);
      setApplications(response.msg?.applications);
      setFilterApplications(response.msg?.applications);
    }
    setLoading(false);
  };

  useEffect(() => {
    getApplications();
  }, []);
  //function to move to next phase automatically when moved to next round
  const [movingNextRound, setMovingNextRound] = useState(false);
  useEffect(() => {
    if (movingNextRound) {
      setActionDialogOpen(false);
      setActive(2);
      setMovingNextRound(false);
    }
  }, [movingNextRound]);

  const getFilter = (id) => {
    switch (id) {
      case 1:
        return "invited";
      case 2:
        return "applied";
    }
  };
  const onClickView = (event, id) => {
    event.stopPropagation();
    setSelectedApplicationID(id);
    setOpen(true);
    setIsTablePopOPen(true);
  };

  useEffect(() => {
    if (filterSelected === 0) {
      setFilterApplications(applications);
      return;
    }
    let arr = [];
    applications.map((application) => {
      const filter = getFilter(filterSelected);
      if (application.status == filter) {
        arr.push(application);
      }
    });
    setFilterApplications(arr);
  }, [filterSelected]);
  useEffect(() => {
    const array = [];
    const dataCheck = [];
    const sdgArray = [];
    const sdgDataCheck = [];
    if (selectedFocusArea?.length > 0) {
      filterData?.map((data) => {
        if (selectedFocusArea?.indexOf(data?.name) !== -1) {
          data?.subFocusArea?.map((subFocusAreaName) => {
            if (dataCheck?.indexOf(subFocusAreaName) === -1) {
              dataCheck.push(subFocusAreaName);
              array?.push({ abbr: subFocusAreaName });
            }
          });
        }
      });
      setFilterApply(false);
      setSubFocusArea(array);
    }
    // if (selectedSubFocusArea?.length > 0) {
    //   filterData?.map((data) => {
    //     data?.subFocusAreas?.map((name) => {
    //       if (selectedSubFocusArea?.indexOf(name) !== -1) {
    //         Data?.sdgs?.map((sdfData) => {
    //           if (sdgDataCheck?.indexOf(sdfData) === -1) {
    //             sdgDataCheck.push(sdfData);
    //             sdgArray?.push({ abbr: sdfData });
    //           }
    //         });
    //       }
    //     });
    //     setFilterApply(false);
    //     setSdg(sdgArray);
    //   });
    // }
  }, [selectedFocusArea, selectedSubFocusArea, selectedSdg]);
  const handleActionManager = (e) => {
    setSelectedAction(e.target.value);
    setActionDialogOpen(true);
  };
  const handleActionDialog = (e, reason) => {
    if (reason == "backdropClick") return;

    if (actionDialogOpen) {
      setSelectedAction(-1);
    }
    setActionDialogOpen(!actionDialogOpen);
  };
  const handleInviteReviewerActionDialog = () => {
    setIsOpenInviteReviewerActionDialog(!isOpenInviteReviewerActionDialog);
  };
  const onClickCheckBox = (e, application) => {
    e.stopPropagation();
    const selectedIndex = selectedRow.indexOf(application._id);
    let newSelected = [];
    let reviewersDetail = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRow, application._id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRow.slice(1));
    } else if (selectedIndex === selectedRow.length - 1) {
      newSelected = newSelected.concat(selectedRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRow.slice(0, selectedIndex),
        selectedRow.slice(selectedIndex + 1)
      );
    }
    if (newSelected?.length > 0) {
      reviewersDetail = filteredApplications.filter((data) => {
        if (newSelected.indexOf(data?._id) !== -1) {
          return data?.reviewer;
        }
      });
    }

    const reviewersData = [];
    const reviewersId = [];
    reviewersDetail.map((data) => {
      data.reviewer.map((Data) => {
        if (reviewersId.indexOf(Data?._id) === -1) {
          reviewersId.push(Data?._id);
          reviewersData.push(Data);
        }
      });
    });
    setReviewers(reviewersData);
    setSelectedRow(newSelected);
  };
  const onSelectAllApplication = (e) => {
    e.stopPropagation();
    if (!isSelectAll) {
      const reviewersData = [];
      const reviewersId = [];
      const applicationIDs = [];
      filteredApplications?.map((data) => {
        applicationIDs.push(data?._id);
        data?.reviewer?.map((Data) => {
          if (reviewersId?.indexOf(Data?._id) === -1) {
            reviewersData?.push(Data);
            reviewersId?.push(Data?._id);
          }
        });
      });
      setReviewers(reviewersData);
      setSelectedRow(applicationIDs);
    } else {
      setReviewers([]);
      setSelectedRow([]);
    }
    setIsselectAll((prev) => !prev);
  };
  const handleFieldApplyFilter = async () => {
    setFilterLoading(true);
    setIsFilterApplied(true);
    const response = await applyFilteres(
      {
        callId: call._id,
        phase: 1,
        filters: {
          sdg: selectedSdg,
          focusArea: selectedFocusArea,
          subFocusArea: selectedSubFocusArea,
        },
        pageNumber: pageNumber === 0 ? 1 : pageNumber + 1,
        pageSize: rowsPerPage,
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      onUpdateTitanNotification1(response);
      setFilterApplications(response?.msg?.applications);
      setApplicationsCount(response?.msg?.totalApplications);
    }
    setFilterLoading(false);
    setFilterApply(true);
  };
  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };
  const onGetDataPageWise = async () => {
    if (
      isFilteredApplied &&
      (selectedFocusArea?.length >= 1 ||
        selectedSubFocusArea?.length >= 1 ||
        selectedSdg?.length >= 1)
    ) {
      handleFieldApplyFilter();
    } else {
      setLoading(true);
      let response;
      if (user?.role?.roleId !== "external_reviewer") {
        let data;
        if (user?.role.roleId === "founder") {
          data = await getApplicationsByReviewer(
            { callId: call._id },
            dispatch,
            controller
          );
          if (data) {
            response = data;
            setIsReviewerDataFound(true);
          }
        }
        if (!data) {
          response = await fetchApplications(
            {
              callId: call._id,
              phase: 1,
              pageNumber: pageNumber === 0 ? 1 : pageNumber + 1,
              pageSize: rowsPerPage,
            },
            dispatch,
            controller
          );
        }
        if (response && response.status == 200) {
          onUpdateTitanNotification1(response);
          setApplicationsCount(response?.msg?.totalApplications);
          setApplications(response.msg?.applications);
          setFilterApplications(response.msg?.applications);
        }
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    onGetDataPageWise();
  }, [pageNumber, rowsPerPage]);
  const handleTitanToStatusActionDialog = () => {
    setIsTitanToStatusDialogueOpen((prev) => !prev);
  };
  return (
    <SchemePhaseTabContainer>
      {!loading && (
        <div className="topRow">
          <CustomTextSearch
            value={textSearch}
            setValue={(value) => setTextSearch(value)}
            upcomingBanner={true}
          />

          <div className="panelRightTableTop">
            {selectedRow.length > 0 && (
              <div className="actions" style={{ marginBottom: "20px" }}>
                <div className="title">Action: </div>
                <div className="input">
                  <Select
                    size="small"
                    value={selectedAction}
                    onChange={handleActionManager}
                  >
                    <MenuItem value={-1}>
                      <FormLabel>Select</FormLabel>
                    </MenuItem>
                    <MenuItem value={"nextRound"}>
                      <FormLabel>Move to next round</FormLabel>
                    </MenuItem>
                    <MenuItem value={"assign"}>
                      <FormLabel>Assign Reviewer</FormLabel>
                    </MenuItem>
                    <MenuItem value={"revoke"}>
                      <FormLabel>Revoke Reviewer</FormLabel>
                    </MenuItem>
                    <MenuItem value={"reject"}>
                      <FormLabel style={{ color: "red" }}>Reject</FormLabel>
                    </MenuItem>
                  </Select>
                </div>
              </div>
            )}
            <Dialog
              open={actionDialogOpen}
              onClose={handleActionDialog}
              PaperProps={{
                style: {
                  minWidth: "30rem",
                },
              }}
            >
              <TitanActionDialog
                handleActionDialog={handleActionDialog}
                activeAction={selectedAction}
                applications={applications}
                getApplicationDataHandlerForManager={getApplications}
                phase={"phase1"}
                setMovingNextRound={setMovingNextRound}
                selectedRow={selectedRow}
                reviewers={reviewers}
              />
            </Dialog>
            <Dialog
              open={isTitanTostatusDialogueOpen}
              onClose={handleTitanToStatusActionDialog}
              PaperProps={{
                style: {
                  width: "25rem",
                },
              }}
            >
              <StatusToTitanActionModel
                handleActionDialog={handleTitanToStatusActionDialog}
                call={call}
              />
            </Dialog>
            {(user?.role.roleId === "super_admin" ||
              user?.role.roleId === "program_manager") && (
              <CustomButton
                title="Export Results"
                onClickHandler={handleTitanToStatusActionDialog}
              />
            )}
            <Dialog
              open={isOpenInviteReviewerActionDialog}
              onClose={handleInviteReviewerActionDialog}
            >
              <InviteReviewerActionDialog
                handleActionDialog={handleInviteReviewerActionDialog}
                getApplicationDataHandlerForManager={getApplications}
              />
            </Dialog>
            {user?.role.roleId !== "external_reviewer" &&
              user.role.roleId !== "founder" && (
                <CustomButton
                  title="Invite"
                  onClickHandler={() => {
                    handleInviteReviewerActionDialog();
                  }}
                />
              )}
            <ExportExcel
              fileName={"titan_list_" + new Date().toLocaleDateString()}
              name={"Export Application Summary"}
              isExportAllData={true}
              dispatch={dispatch}
              controller={controller}
              isProgramManager={isProgramManager}
            />
            {user?.role.roleId !== "external_reviewer" &&
              user.role.roleId !== "founder" && (
                <CustomButton
                  title="Import Applications"
                  onClickHandler={() => {
                    setIsTablePopOPen(false);
                    setOpen(true);
                  }}
                />
              )}
          </div>
        </div>
      )}
      {user?.role.roleId !== "external_reviewer" &&
        user.role.roleId !== "founder" && (
          <div
            className=""
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <MultipleSelectCheckmarks
              Title={"Focus Area"}
              setState={setSelectedFocusArea}
              data={focusArea}
            />
            {selectedFocusArea?.length > 0 && (
              <MultipleSelectCheckmarks
                Title={"Sub Focus Area"}
                setState={setSelectedSubFocusArea}
                data={subFocusArea}
                selectedOptions={selectedFocusArea}
              />
            )}
            {selectedFocusArea?.length > 0 &&
              selectedSubFocusArea?.length > 0 && (
                <MultipleSelectCheckmarks
                  Title={"SDG"}
                  setState={setSelectedSdg}
                  data={sdg}
                  selectedOptions={selectedFocusArea | selectedSubFocusArea}
                />
              )}
            <CustomButton
              title="Apply Filter"
              onClickHandler={() => {
                handleFieldApplyFilter();
              }}
              disabled={filterApply}
            />
          </div>
        )}

      <TableContainer className="tableContainer pm_applicationsTable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {user?.role.roleId !== "external_reviewer" &&
                user.role.roleId !== "founder" && (
                  <TableCell width={"50px"} align="center" aria-checked>
                    <Checkbox
                      color="primary"
                      checked={isSelectAll}
                      onClick={onSelectAllApplication}
                    />
                  </TableCell>
                )}
              {/* <TableCell width={"50px"} align="center" >
								Sl No.
							</TableCell>
							<TableCell align="" width={"350px"}>
								Company Name
							</TableCell> */}
              <TableCell align="" width={"500px"}>
                Title of the Project
              </TableCell>
              {/* <TableCell>Scheme</TableCell> */}
              <TableCell align="">Email ID</TableCell>
              {!isProgramManager && (
                <TableCell align="">Date of Application</TableCell>
              )}
              {/* <TableCell align="">Action</TableCell> */}
              {/* Name of the Applicant */}
              {isProgramManager && (
                <TableCell align="">Name of the Applicant</TableCell>
              )}
              {isProgramManager && <TableCell align="">Reviewers</TableCell>}
              {active == 6 ? (
                <TableCell align="">Project Management</TableCell>
              ) : (
                !isProgramManager && (
                  <TableCell align="center">
                    Status
                    <Select
                      size="small"
                      IconComponent={FilterAlt}
                      value={filterSelected}
                      sx={{ marginLeft: "0.5rem" }}
                      onChange={(e) => setFilter(e.target.value)}
                    >
                      <MenuItem value={0}>All</MenuItem>
                      <MenuItem value={1}>Invited</MenuItem>
                      <MenuItem value={2}>Applied</MenuItem>
                    </Select>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading || filterLoading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="loading">
                    <img src={Gif.flying} alt="loading" />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              filteredApplications.map((application, index) => {
                const isSelected =
                  selectedRow.indexOf(application._id) !== -1 ? true : false;

                return (
                  <TableRow
                    key={index}
                    className="applicationRow"
                    onClick={() =>
                      navigate(`/schemes/titan/${call._id}/${application._id}`)
                    }
                  >
                    {user?.role.roleId !== "external_reviewer" &&
                      user.role.roleId !== "founder" && (
                        <TableCell
                          align="center"
                          onClick={(e) => onClickCheckBox(e, application)}
                        >
                          <Checkbox color="primary" checked={isSelected} />
                        </TableCell>
                      )}
                    {/* <TableCell align="center">{index + 1}</TableCell>
										<TableCell
											align=""
											
										>
											{application.companyName}
										</TableCell> */}
                    <TableCell align="">
                      {application?.projectTitle || "-"}
                    </TableCell>
                    {/* <TableCell>{application.scheme}</TableCell> */}
                    <TableCell align="">
                      {application?.applicantEmail}
                    </TableCell>
                    {isProgramManager && (
                      <TableCell align="">
                        {application?.applicantName}
                      </TableCell>
                    )}
                    {isProgramManager && (
                      <TableCell align="">
                        {application?.reviewer?.map((data, index) => {
                          if (index + 1 === application?.reviewer?.length) {
                            return `${data?.name}`;
                          } else {
                            return `${data?.name}, `;
                          }
                        })}
                      </TableCell>
                    )}
                    {!isProgramManager && (
                      <TableCell align="">
                        {application?.createdOn
                          ? new Date(
                              application?.createdOn
                            ).toLocaleDateString()
                          : "-"}
                      </TableCell>
                    )}
                    {/* <TableCell align="" onClick={(e) => onClickView(e, application?._id)}>
											Evaluate
										</TableCell> */}
                    {active == 6 ? (
                      <TableCell align="">
                        <Button
                          variant="outlined"
                          className="muiOutlinedBtn"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/schemes/milestones/${application?._id}`);
                          }}
                        >
                          Project Management
                        </Button>
                      </TableCell>
                    ) : (
                      !isProgramManager && (
                        <TableCell align="center">
                          <span className="statusText">
                            {ApplicationStatus[application?.status] ||
                              application?.status}
                          </span>
                        </TableCell>
                      )
                    )}
                  </TableRow>
                );
              })
            )}

            {!loading && !filterLoading && filteredApplications?.length == 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="no-application">No Applications!</div>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
          {user?.role?.roleId !== "external_reviewer" &&
            user.role.roleId !== "founder" &&
            !isReviewerDataFound && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={pageNumber}
                    count={applicationsCount || 0}
                  />
                </TableRow>
              </TableFooter>
            )}
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={modalHandler}
        PaperProps={{
          style: {
            width: !isTablePopOpen ? "30rem" : "100rem",
          },
        }}
        maxWidth={"100vw"}
      >
        {isTablePopOpen ? (
          <TablePopUp
            modalHandler={modalHandler}
            applicationsID={applicationsID}
            selectedApplicationID={selectedApplicationID}
            setSelectedApplicationID={setSelectedApplicationID}
          />
        ) : (
          <ExcelFileUploadDialog modalHandler={modalHandler} />
        )}
      </Dialog>
    </SchemePhaseTabContainer>
  );
};

export default Applications;

export const SchemePhaseTabContainer = styled.div`
  .contactPanel {
    border: 1px solid #d0d3d4;
    padding: 1rem;
    width: max-content;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    color: var(--text3);
    margin: 0 auto;
    margin-top: 2rem;

    .text {
      font-size: 20px;
      letter-spacing: 2px;
      display: flex;
      justify-content: center;
    }

    .number {
      display: flex;
      gap: 0.5rem;
      justify-content: center;

      .numberText {
        color: var(--text1);
      }
    }
  }

  .statusTextApplication {
    font-size: 1.1rem;
    text-transform: capitalize;
    padding: 0.5rem 1rem;
    width: max-content;
    border-radius: 8px;
  }

  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    .panelRightTableTop {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }

  .no-application {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 1.2rem;
    color: var(--errorTextColor);
  }

  .apply {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }

  .container {
    .actions {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 2rem;
      justify-content: flex-end;

      .title {
        color: var(--text1);
      }
    }
  }

  .tableContainer {
    background: #fff;
    margin-top: 2rem;

    .loading {
      display: flex;
      justify-content: center;

      img {
        width: 10rem;
      }
    }

    table {
      thead {
        tr {
          th {
            color: var(--text1);
            font-size: 1rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .pm_applicationsTable {
    .statusText {
      text-transform: capitalize;
    }

    .applicationRow {
      cursor: pointer;

      &:hover {
        background: rgba(1, 210, 178, 0.2);
      }
    }
  }

  .applicantTable {
    .statusText {
      text-transform: capitalize;
    }

    table {
      thead {
        tr {
          th {
            color: var(--text3);
            font-size: 1.1rem;
            font-weight: 400;
          }
        }
      }

      tbody {
        tr {
          td {
            color: var(--text1);
          }
        }
      }
    }

    .subtextAction {
      color: var(--text3);
      cursor: pointer;
    }

    .text {
      font-size: 1.1rem;
    }

    .actionText {
      font-size: 1.1rem;
      color: var(--primary);
      cursor: pointer;
      transition: 0.1s;

      &:hover {
        color: green;
      }
    }
  }
`;
