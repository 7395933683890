import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Input,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../../providers/GlobalProvider";
import {
  fetchApplications,
  getAllApplicationsID,
  getApplicationSubphaseData,
  getApplicationsByReviewer,
  getFormData,
  saveApplicationData,
  saveComment,
  saveFormInputs,
} from "../../../../../../../data/network/api/scheme";
import { uploadFile } from "../../../../../../../data/network/api/startupForm";
import BuildFormField from "../../../../widgets/FormBuilder";
import { Icons } from "../../../../../../../utils/mediaExports";
import CustomButton from "../../../../../../customComponents/CustomButton";
import PassportPhotoUpload from "../../../../../../customComponents/PassportPhotoUpload";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { MuiTelInput } from "mui-tel-input";
import { BuildFileUploader } from "../../../../../../widgetManager/componentBuilder";
import validator from "validator";
import { inrAddCommas } from "../../../../../../../utils/helperHandler";
import OwlLoader from "../../../../../../customComponents/OwlLoader";
import TablePopUp from "../../../../../customComponents/modals/TablePopUp";
import { useParams, useNavigate } from "react-router-dom";

// const fields = {
//   title: {
//     title: "1. Title of the proposed project",
//     inputs: {
//       title: {
//         id: "title",
//         title: "Title",
//         sl: 1,
//         label: "Title of the proposed project :",
//         inputType: "text",
//         default: "",
//       },
//       photo: {
//         id: "photo",
//         label: "Upload your latest photo",
//         default: [],
//         inputType: "file",
//         mt: true,
//         fileType: ["jpg", "jpeg", "png", "svg", "pdf"],
//       },
//     },
//   },
//   personalDetails: {
//     title: "2.1. Personal Details",
//     inputs: {
//       name: {
//         id: "name",
//         title: "Innovator Details",
//         sl: 2,
//         label: "2.1 Personal Details\na) Name of the Innovator",
//         inputType: "text",
//         default: "",
//       },
//       fatherName: {
//         id: "fatherName",
//         label: "b) Father's name/Husband's name",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       dob: {
//         id: "dob",
//         label: "c) Date of Birth",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       email: {
//         id: "email",
//         label: "d) Email Id",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       mobile: {
//         id: "mobile",
//         label: "e) Mobile Number",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       landline: {
//         id: "landline",
//         label: "f) Landline Number",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       gender: {
//         id: "gender",
//         label: "g) Gender",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       qualification: {
//         id: "qualification",
//         label: "h) Educational Qualification",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       category: {
//         id: "category",
//         label: "i) Category (General/SC/ST/OBC/Others)",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       pan: {
//         id: "pan",
//         label: "i) PAN",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       aadhar: {
//         id: "aadhar",
//         label: "k) Aadhar",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       address: {
//         id: "address",
//         label:
//           "l) Address : (Please enclose residence certificate issued by Sub-Divisional Magistrate(SDM)/District Magistrate(DM) or a copy of ration card or any other document regarding proof of Residence)\nPostal Address :",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//       permanentAddress: {
//         id: "permanentAddress",
//         label: "Permanent Address : ",
//         inputType: "text",
//         default: "",
//         mt: true,
//       },
//     },
//   },
//   professionalDetails: {
//     title: "2.2. Professional Details",
//     inputs: {
//       profession: {
//         id: "profession",
//         inputType: "text",
//         title: "Professional Details",
//         sl: 2.2,
//         default: "",
//         label: "a) Profession of the Innovator",
//       },
//       organization: {
//         id: "organization",
//         inputType: "text",
//         default: "",
//         label:
//           "b) Name & Address of the Institution/Organization (applicable in case of Innovator in Employment / Students with any R& D organisation / Academic Institution:-",
//         mt: true,
//       },
//       noc: {
//         id: "noc",
//         inputType: "file",
//         default: [],
//         label:
//           "c) For Innovator in Employment / Students with any R& D organisation / Academic Institution  No Objection Certificate  Upload NOC from Head of Institute /Organization is mandatorily required)",
//         mt: true,
//         fileType: ["jpg", "jpeg", "png", "svg", "pdf"],
//       },
//       annualIncome: {
//         id: "annualIncome",
//         inputType: "text",
//         default: "",
//         label:
//           "d) Annual Income of the applicant (applicable in case of Innovator in Employment)",
//         mt: true,
//       },
//       itr: {
//         id: "itr",
//         inputType: "file",
//         default: [],
//         label: "Attach a copy of the latest Income Tax Returns filed",
//         mt: true,
//         fileType: ["jpg", "jpeg", "png", "svg", "pdf"],
//       },
//       ownVenture: {
//         id: "ownVenture",
//         inputType: "text",
//         default: "",
//         label:
//           "e) Have you started your own venture (start up) ? Yes / No  ----  If yes, please mention the following : Name of your start up, Your Role in start up (eg. CEO, COO, Product Head etc), Date of Incorporation, Revenue generated till date, – Provide Cap structure of the startup -",
//         mt: true,
//       },
//       founders: {
//         id: "founders",
//         inputType: "table",
//         default: [],
//         label: "",
//         theadings: [
//           "Name (Founder/Co-founder)",
//           "Nationality",
//           "Commitment (Part time/Full time",
//           "% Shareholding",
//         ],
//       },
//     },
//   },
//   teamMembers: {
//     title: "2.3. Team Members",
//     inputs: {
//       teamMembers: {
//         id: "teamMembers",
//         inputType: "table",
//         title: "Team Member",
//         sl: 2.3,
//         theadings: [
//           "Name",
//           "Aadhar No.",
//           "PAN Card no.",
//           "Gender",
//           "Contact",
//           "Email-id",
//           "Qualification",
//         ],
//         default: [],
//         label: "2.3 Team Member Details",
//       },
//     },
//   },
//   description: {
//     title: "3. Brief Description",
//     inputs: {
//       description: {
//         id: "description",
//         default: "",
//         inputType: "text",
//         title: "Description",
//         sl: 3,
//         label: "Brief description of the idea highlighting innovative element.",
//       },
//     },
//   },
//   otherDetails: {
//     title: "4. Other Details",
//     inputs: {
//       statusOfWork: {
//         id: "statusOfWork",
//         title: "Status of Work",
//         sl: 4,
//         label:
//           "4. a) Status of work already carried out (if any) such as. Please tick ✓ as applicable. ",
//         default: "",
//         inputType: "radio",
//         options: [
//           "Idea level",
//           "College Project",
//           "Lab level – Proof of Concept",
//           "Paper Presentation",
//           "Working Prototype ready",
//         ],
//       },
//       workingPrinciple: {
//         id: "workingPrinciple",
//         label:
//           "b) Science and working principle behind the idea (Not more than 250 words).",
//         default: "",
//         inputType: "text",
//         mt: true,
//       },
//       outcome: {
//         id: "outcome",
//         label:
//           "c) Final outcome/deliverable of the project (Not more than 150 words)",
//         default: "",
//         inputType: "text",
//         mt: true,
//       },
//       beneficiary: {
//         id: "beneficiary",
//         label:
//           "d) Who would be the beneficiary of this innovation and why? (Not more than 200 words)",
//         default: "",
//         inputType: "text",
//         mt: true,
//       },
//     },
//   },
//   costs: {
//     title: "5. Proposed costs and time frame",
//     inputs: {
//       items: {
//         id: "items",
//         inputType: "staticTable",
//         columnInputType: "text",
//         default: {},
//         label: "5. Proposed costs and time frame",
//         columns: ["Items", "Project Cost(Own Share)", "titan support sought"],
//         rows: [
//           "Outsourcing Charges for R&D/Design Engg/Consultancy/Testing Expert cost",
//           "Raw material/ Consumables/Spares",
//           "Fabrication /Synthesis charges of working model or process",
//           "Business Travel and Event participation Fees (Ceiling 10% of approved project cost)",
//           "Patent filing Cost – (PCT- Ceiling 10% of approved project cost)",
//           "Contingency - (Ceiling 10% of approved project cost)",
//         ],
//         columnIds: ["items", "ownShare", "titan"],
//       },
//       projectPeriod: {
//         id: "projectPeriod",
//         inputType: "text",
//         default: "",
//         label: "Project period in months (Not more than 12 months)",
//         mt: true,
//       },
//     },
//   },
//   financialSupport: {
//     title: "6. Financial Support from other sources",
//     inputs: {
//       financialSupport: {
//         id: "financialSupport",
//         sl: 6,
//         title: "Financial Support",
//         label:
//           "Have you received financial support / award for your present work from any other sources? (if so, please furnish details)",
//         default: "",
//         inputType: "text",
//       },
//     },
//   },
//   otherCenter: {
//     title: "7. Other titan Centre",
//     inputs: {
//       otherCenter: {
//         id: "otherCenter",
//         sl: 7,
//         title: "Other titan Centre",
//         label:
//           "Have you also applied / in process of applying to any other titan Centre (s) for NIDHI-titan  and/or NIDHI-EIR grant  --    Yes/ No \nIf Yes – Please mention the name of the Centre / TBI and the program. ",
//         default: "",
//         inputType: "text",
//       },
//     },
//   },
//   received: {
//     title: "8. titan Recieved/approved",
//     inputs: {
//       received: {
//         id: "received",
//         sl: 8,
//         title: "titan Received/Approved",
//         label:
//           "Have you been approved / received the NIDHI-titan --	Yes/ No \nIf Yes – Please mention the name of the Centre / TBI  and Date of approval/Funds received",
//         default: "",
//         inputType: "text",
//       },
//     },
//   },
//   criteria: {
//     title: "9. Essential Criteria",
//     inputs: {
//       criteria: {
//         id: "criteria",
//         label: "9. Essential Criteria",
//         default: ["", "", "", "", "", ""],
//         inputType: "booleanTable",
//         rows: [
//           "Applicant Confirms that he/she has not been a recipient of any NIDHI-titan  or similar  support previously for the same innovative concept as proposed in this form. NIDHI-titan can be used only once.",
//           "Applicant is planning to pursue NIDHI- titan full time with no other concurrent commitments",
//           "Applicant confirms that he/she is fully committed to work towards the prototype development for which the support is being sought and should not treat this as a stop gap arrangement to support any other pursuits",
//           "Applicant has or is planning to register for the pre incubation or incubation program at the TBI for the entire duration of titan support",
//           "Applicant confirms that if selected for NIDHI-titan, then they (applicant and their team members) will avail support for their project from only one titan Centre. If the applicant is found seeking NIDHI-titan support for the same idea from two different PCs, then the PC reserves right to discontinue the titan support and appropriate action will be taken on the applicant.",
//           "Applicant confirms that they will not avail the funding support in parallel for both the schemes i.e NIDHI-titan and NIDHI - EIR from the same or different centers.",
//         ],
//       },
//     },
//   },
//   declaration: {
//     title: "10. Declaration",
//     inputs: {
//       place: {
//         inputType: "text",
//         sl: 10,
//         title: "Declaration",
//         label:
//           "I declare that all the statements made in this application are true, complete and correct to the best of my knowledge and belief.  If any information is found false or incorrect, my candidature will stand cancelled and all my claims will be forfeited. I have not received any financial assistance for the present proposal from any other agency.\n\nPlace :",
//         default: "",
//         id: "place",
//       },
//       date: {
//         inputType: "text",
//         label: "Date : ",
//         default: "",
//         id: "date",
//         mt: true,
//       },
//       signature: {
//         id: "signature",
//         label: "Signature : ",
//         default: [],
//         inputType: "file",
//         mt: true,
//         fileType: ["jpg", "jpeg", "png", "svg", "pdf"],
//       },
//     },
//   },
// };

// const initialData = {
//   title: {
//     title: "",
//     photo: [],
//   },
//   personalDetails: {
//     name: "",
//     fatherName: "",
//     dob: "",
//     email: "",
//     mobile: "+91",
//     landline: "",
//     gender: "",
//     qualification: "",
//     category: "",
//     pan: "",
//     panFile: null,
//     aadhaarFile: null,
//     aadhar: "",
//     addressFile: null,
//     address: {
//       address: "",
//       city: "",
//       state: "",
//       pincode: "",
//     },
//     permanentAddress: {
//       address: "",
//       city: "",
//       state: "",
//       pincode: "",
//     },
//   },
//   professionalDetails: {
//     profession: "",
//     organization: "",
//     noc: null,
//     annualIncome: "",
//     itr: null,
//     founders: [],
//     ownVenture: {
//       isExists: "no",
//       details: "",
//     },
//   },
//   teamMembers: {
//     teamMembers: [],
//   },
//   description: {
//     description: "",
//   },
//   otherDetails: {
//     statusOfWork: "",
//     workingPrinciple: "",
//     outcome: "",
//     beneficiary: "",
//   },
//   costs: {
//     items: {
//       item1: {
//         ownShare: "0",
//         titan: "0",
//       },
//       item2: {
//         ownShare: "0",
//         titan: "0",
//       },
//       item3: {
//         ownShare: "0",
//         titan: "0",
//       },
//       item4: {
//         ownShare: "0",
//         titan: "0",
//       },
//       item5: {
//         ownShare: "0",
//         titan: "0",
//       },
//       item6: {
//         ownShare: "0",
//         titan: "0",
//       },
//     },
//     projectPeriod: "",
//   },
//   financialSupport: {
//     financialSupport: {
//       isSupport: "no",
//       details: "",
//     },
//   },
//   otherCenter: {
//     otherCenter: {
//       isOtherCenter: "no",
//       details: "",
//     },
//   },
//   received: {
//     received: {
//       isReceived: "no",
//       details: "",
//     },
//   },
//   criteria: {
//     criteria: ["yes", "yes", "yes", "yes", "yes", "yes"],
//   },
//   declaration: {
//     place: "",
//     date: new Date().toString(),
//     signature: null,
//   },
// };

const initialData = {
  title: {
    data: {
      title: "",
      photo: [],
    },
    status: "pending",
    comment: "",
  },
  personalDetails: {
    data: {
      name: "",
      fatherName: "",
      dob: "",
      email: "",
      mobile: "+91",
      landline: "",
      gender: "",
      qualification: "",
      category: "",
      pan: "",
      panFile: null,
      aadhaarFile: null,
      aadhar: "",
      addressFile: null,
      address: {
        address: "",
        city: "",
        state: "",
        pincode: "",
      },
      permanentAddress: {
        address: "",
        city: "",
        state: "",
        pincode: "",
      },
    },
    status: "pending",
    comment: "",
  },
  professionalDetails: {
    data: {
      profession: "",
      organization: "",
      noc: null,
      annualIncome: "",
      itr: null,
      founders: [],
      ownVenture: {
        isExists: "no",
        details: "",
      },
    },
    status: "pending",
    comment: "",
  },
  teamMembers: {
    data: {
      teamMembers: [],
    },
    status: "pending",
    comment: "",
  },
  description: {
    data: {
      opportunity: "",
      cause: "",
    },
    status: "pending",
    comment: "",
  },
  otherDetails: {
    data: {
      statusOfWork: "",
      brief: "",
      pitch: "",
      features: "",
      risks: "",
      sustainability: "",
      validation: {
        isValidated: "no",
        details: "",
      },
      rights: {
        hasRights: "no",
        evidence: null,
        details: "",
      },
    },
    status: "pending",
    comment: "",
  },
};
const mandatoryFields = {
  title: {
    title: {
      mandatory: true,
      error: "",
    },
    photo: {
      mandatory: false,
      error: "",
    },
  },
  personalDetails: {
    name: {
      mandatory: true,
      error: "",
    },
    fatherName: {
      mandatory: false,
      error: "",
    },
    dob: {
      mandatory: true,
      error: "",
    },
    email: {
      mandatory: true,
      error: "",
    },
    mobile: {
      mandatory: true,
      error: "",
    },
    landline: {
      mandatory: false,
      error: "",
    },
    gender: {
      mandatory: true,
      error: "",
    },
    qualification: {
      mandatory: false,
      error: "",
    },
    category: {
      mandatory: false,
      error: "",
    },
    pan: {
      mandatory: false,
      error: "",
    },
    panFile: {
      mandatory: false,
      error: "",
    },
    aadhaarFile: {
      mandatory: false,
      error: "",
    },
    aadhar: {
      mandatory: false,
      error: "",
    },
    addressFile: {
      mandatory: false,
      error: "",
    },
    address: {
      address: {
        mandatory: true,
        error: "",
      },
      city: {
        mandatory: true,
        error: "",
      },
      state: {
        mandatory: true,
        error: "",
      },
      pincode: {
        mandatory: true,
        error: "",
      },
    },
    permanentAddress: {
      address: {
        mandatory: false,
        error: "",
      },
      city: {
        mandatory: false,
        error: "",
      },
      state: {
        mandatory: false,
        error: "",
      },
      pincode: {
        mandatory: false,
        error: "",
      },
    },
  },
  professionalDetails: {
    profession: {
      mandatory: false,
      error: "",
    },
    organization: {
      mandatory: false,
      error: "",
    },
    noc: {
      mandatory: false,
      error: "",
    },
    annualIncome: {
      mandatory: false,
      error: "",
    },
    itr: {
      mandatory: false,
      error: "",
    },
    founders: {
      mandatory: false,
      error: "",
    },
    ownVenture: {
      isExists: {
        mandatory: true,
        error: "",
      },
      details: {
        mandatory: false,
        error: "",
      },
    },
  },
  teamMembers: {
    teamMembers: {
      mandatory: false,
      error: "",
    },
  },
  description: {
    opportunity: {
      mandatory: true,
      error: "",
    },
    cause: {
      mandatory: true,
      error: "",
    },
  },
  otherDetails: {
    statusOfWork: {
      mandatory: true,
      error: "",
    },
    brief: {
      mandatory: true,
      error: "",
    },
    pitch: {
      mandatory: true,
      error: "",
    },
    features: {
      mandatory: true,
      error: "",
    },
    risks: {
      mandatory: false,
      error: "",
    },
    sustainability: {
      mandatory: true,
      error: "",
    },
    rights: {
      hasRights: {
        mandatory: false,
        error: "",
      },
      details: {
        mandatory: false,
        error: "",
      },
      evidence: {
        mandatory: false,
        error: "",
      },
    },
  },
  // costs: {
  //   items: {
  //     item1: {
  //       ownShare: { mandatory: true, error: "" },
  //       titan: { mandatory: true, error: "" },
  //     },
  //     item2: {
  //       ownShare: { mandatory: true, error: "" },
  //       titan: { mandatory: true, error: "" },
  //     },
  //     item3: {
  //       ownShare: { mandatory: true, error: "" },
  //       titan: { mandatory: true, error: "" },
  //     },
  //     item4: {
  //       ownShare: { mandatory: true, error: "" },
  //       titan: { mandatory: true, error: "" },
  //     },
  //     item5: {
  //       ownShare: { mandatory: true, error: "" },
  //       titan: { mandatory: true, error: "" },
  //     },
  //     item6: {
  //       ownShare: { mandatory: true, error: "" },
  //       titan: { mandatory: true, error: "" },
  //     },
  //   },
  //   projectPeriod: {
  //     mandatory: true,
  //     error: "",
  //   },
  // },
  // financialSupport: {
  //   financialSupport: {
  //     isSupport: {
  //       mandatory: true,
  //       error: "",
  //     },
  //     details: {
  //       mandatory: true,
  //       error: "",
  //     },
  //   },
  // },
  // otherCenter: {
  //   otherCenter: {
  //     isOtherCenter: {
  //       mandatory: false,
  //       error: "",
  //     },
  //     details: {
  //       mandatory: false,
  //       error: "",
  //     },
  //   },
  // },
  // received: {
  //   received: {
  //     isReceived: {
  //       mandatory: false,
  //       error: "",
  //     },
  //     details: {
  //       mandatory: false,
  //       error: "",
  //     },
  //   },
  // },
  // criteria: {
  //   criteria: {
  //     mandatory: false,
  //     error: "",
  //   },
  // },
  // declaration: {
  //   place: {
  //     mandatory: true,
  //     error: "",
  //   },
  //   date: {
  //     mandatory: true,
  //     error: "",
  //   },
  //   signature: {
  //     mandatory: true,
  //     error: "",
  //   },
  // },
};

const tableFields = {
  professionalDetails: {
    founders: {
      name: "",
      nationality: "",
      commitment: "",
      shareholding: "",
    },
  },
  teamMembers: {
    teamMembers: {
      name: "",
      aadhaar: "",
      pan: "",
      gender: "",
      contact: "",
      email: "",
      qualification: "",
    },
  },
  // costs: {
  //   costs: {
  //     items: "Items",
  //     ownShare: "Project Cost(Own Share)",
  //     titan: "titan support sought",
  //   },
  //   costsRows: [
  //     "Outsourcing Charges for R&D/Design Engg/Consultancy/Testing Expert cost",
  //     "Raw material/ Consumables/Spares",
  //     "Fabrication /Synthesis charges of working model or process",
  //     "Business Travel and Event participation Fees (Ceiling 10% of approved project cost)",
  //     "Patent filing Cost – (PCT- Ceiling 10% of approved project cost)",
  //     "Contingency - (Ceiling 10% of approved project cost)",
  //   ],
  // },
  criteriaRows: [
    "Applicant Confirms that he/she has not been a recipient of any NIDHI-titan  or similar  support previously for the same innovative concept as proposed in this form. NIDHI-titan can be used only once.",
    "Applicant is planning to pursue NIDHI- titan full time with no other concurrent commitments",
    "Applicant confirms that he/she is fully committed to work towards the prototype development for which the support is being sought and should not treat this as a stop gap arrangement to support any other pursuits",
    "Applicant has or is planning to register for the pre incubation or incubation program at the TBI for the entire duration of titan support",
    "Applicant confirms that if selected for NIDHI-titan, then they (applicant and their team members) will avail support for their project from only one titan Centre. If the applicant is found seeking NIDHI-titan support for the same idea from two different PCs, then the PC reserves right to discontinue the titan support and appropriate action will be taken on the applicant.",
    "Applicant confirms that they will not avail the funding support in parallel for both the schemes i.e NIDHI-titan and NIDHI - EIR from the same or different centers.",
  ],
};

const CompletionOfForm = ({
  handleForm,
  application,
  applicationData,
  call,
  from,
  activeStep,
}) => {
  const [data, setData] = useState(JSON.parse(JSON.stringify(initialData)));
  const dispatch = globalDispatch();
  const controller = new AbortController();
  const [fileLoading, setFileLoading] = useState({
    proposalConceptFiles: false,
    tableFile: false,
    documents: false,
  });
  const params = useParams();
  const navigate = useNavigate();
  const [openComments, setOpenComments] = useState(false);
  const [open, setOpen] = useState(false);
  const [commentsStatus, setCommentsStatus] = useState({});
  const [commentsStatusState, setCommentsStatusState] = useState({});
  const [formVersion, setFormVersion] = useState(0);
  const [applicationsID, setApplicationsID] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const { user } = globalSelector((state) => state.auth);

  const [formLoading, setFormLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFormDataHandler();
  }, []);
  const getFormDataHandler = async () => {
    setFormLoading(true);
    const response = await getFormData(
      {
        formName: "innovatorForm",
        applicationId: application._id,
      },
      dispatch,
      controller
    );
    let response1 = "";

    if (
      user?.role.roleId === "external_reviewer" ||
      user.role.roleId === "founder"
    ) {
      response1 = await getApplicationsByReviewer(
        { callId: call._id },
        dispatch,
        controller
      );
    } else {
      response1 = await getAllApplicationsID(
        { callId: call._id, phase: 1 },
        dispatch,
        controller
      );
    }

    if (response1 && response1?.status == 200) {
      const applicationsIDArray = response1?.msg?.applications.map(
        (application) => application?._id
      );
      setApplicationsID(applicationsIDArray);
      //   console.log(response1?.msg);
    }
    if (response && response.status == 200) {
      let dataObj = {};
      let commentObj = {};

      Object.keys(initialData).map((field, i) => {
        dataObj[field] = response.msg[field];
        commentObj[field] = {
          comment: response.msg[field]?.comment,
          status: response.msg[field]?.status,
        };
      });
      setData(dataObj);
      setCommentsStatus({ ...commentObj });
      setCommentsStatusState({ ...commentObj });
      setFormVersion(response?.msg?.__v);
      setFormLoading(false);
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const changeComment = (id, value) => {
    setCommentsStatusState({
      ...commentsStatusState,
      [id]: { ...commentsStatusState[id], comment: value },
    });
  };

  const handleApproveCheckbox = (id, e) => {
    setCommentsStatusState({
      ...commentsStatusState,
      [id]: {
        ...commentsStatusState[id],
        status: e.target.checked ? "approved" : "pending",
      },
    });
  };

  const handleChange = (field, id, value) => {
    setData({ ...data, [field]: { ...data[field], [id]: value } });
  };

  const handleCheckbox = (field, id, value, isChecked) => {
    setData({
      ...data,
      [field]: {
        ...data[field],
        [id]: { ...data[field][id], [value]: isChecked },
      },
    });
  };

  const removeFile = (field, id, index) => {
    let array = [...data[field][id]];
    array.splice(index, 1);
    handleChange(field, id, array);
  };

  const saveCommentHandler = async (id, name) => {
    const response = await saveComment(
      {
        applicationId: application._id,
        fieldId: id,
        values: commentsStatusState[id],
        formName: "innovatorForm",
        formVersion: formVersion,
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      snackbarHandler(`Comment for ${name} submitted!`, "success");
      let dataObj = {};
      let commentObj = {};
      Object.keys(initialData).map((field, i) => {
        dataObj[field] = response.msg[field]?.data;
        commentObj[field] = {
          comment: response.msg[field]?.comment,
          status: response.msg[field]?.status,
        };
      });
      setData(dataObj);
      setCommentsStatus({ ...commentObj });
      setCommentsStatusState({ ...commentObj });
      setFormVersion(response?.msg?.__v);
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
  };

  const snackbarHandler = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  // const addRow = (id) => {
  //   let obj = {};
  //   let parent = "";
  //   if (id == "founders") {
  //     parent = "professionalDetails";
  //     obj = {
  //       name: "",
  //       nationality: "",
  //       commitment: "",
  //       shareholding: "",
  //     };
  //   } else if (id == "teamMembers") {
  //     parent = "teamMembers";
  //     obj = {
  //       name: "",
  //       aadhar: "",
  //       pan: "",
  //       gender: "",
  //       contact: "",
  //       email: "",
  //       qualification: "",
  //     };
  //   }

  //   setData({
  //     ...data,
  //     [parent]: { ...data[parent], [id]: [...data[parent][id], { ...obj }] },
  //   });
  // };

  // const deleteRow = (id, index) => {
  //   let parent = "";
  //   if (id == "founders") {
  //     parent = "professionalDetails";
  //   } else if (id == "teamMembers") {
  //     parent = "teamMembers";
  //   }
  //   let array = [...data[parent][id]];
  //   array.splice(index, 1);
  //   setData({
  //     ...data,
  //     [parent]: { ...data[parent], [id]: [...array] },
  //   });
  // };

  // const changeRowTfValue = (index, id, field, value) => {
  //   let parent = "";
  //   if (id == "founders") {
  //     parent = "professionalDetails";
  //   } else if (id == "teamMembers") {
  //     parent = "teamMembers";

  //   }
  //   let array = [...data[parent][id]];
  //   let obj = { ...array[index] };
  //   obj[field] = value;
  //   array.splice(index, 1, { ...obj });
  //   setData({
  //     ...data,
  //     [parent]: { ...data[parent], [id]: [...array] },
  //   });
  // };

  //new form handlers
  const [isCopyPostalAddress, setPostalAddressCopy] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState([]);
  const [error, setError] = useState(
    JSON.parse(JSON.stringify(mandatoryFields))
  );
  // now the  flow related to the founder is same as the external reviewer so i have kept this state as false in future check it once is anything changes
  const [isFounderOrReviewer, setIsFounderOrReviewer] = useState(
    user.role.roleId === "external_reviewer" || user.role.roleId === "founder"
  );
  const [isFounder, setIsFounder] = useState(false);
  //handler to change value of textfields in the form
  const handleTextChange = (category, id, value, subCategory = null) => {
    if (subCategory) {
      setData({
        ...data,
        [category]: {
          ...data[category],
          data: {
            ...data[category].data,
            [id]: { ...data[category].data[id], [subCategory]: value },
          },

          // [id]: { ...data[category][id], [subCategory]: value },
        },
      });
      return;
    }
    setData({
      ...data,
      [category]: {
        ...data[category],
        data: {
          ...data[category].data,
          [id]: value,
        },
      },
      // [id]: value },
    });
  };

  //handler to copy address from postal to permanent
  const handleCopyAddress = (e) => {
    if (e.target.checked) {
      setPostalAddressCopy(true);
      const permanentAddress = JSON.parse(
        JSON.stringify(data.personalDetails.data.permanentAddress)
      );
      setData({
        ...data,
        personalDetails: {
          ...data["personalDetails"],
          data: {
            ...data["personalDetails"].data,
            address: { ...permanentAddress },
          },
          // address: { ...permanentAddress },
        },
      });
      return;
    }
    setPostalAddressCopy(false);
  };

  //handlers realted to table in form (add rows, delete rows, change text box values in table cell)
  //handler to push a row in table with related data fields
  const addRowToTable = (category, id) => {
    const tableFieldsData = { ...tableFields[category][id] };
    let array = [...data[category].data[id]];
    array.push({ ...tableFieldsData });
    setData({
      ...data,
      [category]: {
        ...data[category],
        data: { ...data[category].data, [id]: array },
      },
    });
  };

  //handler to delete a row from the table
  const deleteRowTable = (category, id, index) => {
    let array = [...data[category].data[id]];
    array.splice(index, 1);
    setData({
      ...data,
      [category]: {
        ...data[category],
        data: { ...data[category].data, [id]: array },
      },
    });
  };

  //handler to change value of textbox in table cell
  const handleChangeTextTable = (category, subCategory, id, value, index) => {
    let array = [...data[category].data[subCategory]];
    array[index][id] = value;
    setData({
      ...data,
      [category]: {
        ...data[category],
        data: {
          ...data[category].data,
          [subCategory]: [...array],
        },
      },
      // [subCategory]: [...array] },
    });
  };

  //handler to change static table values
  const changeStaticTableValue = (id, rowId, field, value) => {
    let parent = "";
    if (id == "items") {
      parent = "costs";
    }
    setData({
      ...data,
      [parent]: {
        ...data[parent],
        [id]: {
          ...data[parent][id],
          [rowId]: { ...data[parent][id][rowId], [field]: value },
        },
      },
    });
  };

  //handler to change values of boolean table
  const changeBooleanTableValue = (id, index, value) => {
    let parent = "";
    if (id == "criteria") {
      parent = "criteria";
    }

    let array = [...data[parent][id]];
    array[index] = value;
    setData({ ...data, [parent]: { ...data[parent], [id]: array } });
  };

  //end of handlers realted to table in form (add rows, delete rows, change text box values in table cell)

  //start of handlers related to files in form
  const handleUpload = async (file, field, id) => {
    setFileLoading({ ...fileLoading, [id]: true });
    const fileData = new FormData();
    fileData.append("file", file);
    const response = await uploadFile(fileData, dispatch, controller);
    if (response && response.status == 200) {
      setData({
        ...data,
        [field]: {
          ...data[field],
          data: {
            ...data[field].data,
            [id]: [
              ...data[field].data[id],
              { name: file.name, url: response.msg },
            ],
          },
        },
      });
    } else {
      snackbarHandler(`Failed to upload ${file?.name || ""}!`, "error");
    }
    setFileLoading({ ...fileLoading, [id]: false });
  };

  const handleSingleUpload = async (file, field, subCategory = null, id) => {
    setFileLoading({ ...fileLoading, [id]: true });
    const fileData = new FormData();
    fileData.append("file", file);
    const response = await uploadFile(fileData, dispatch, controller);
    if (response && response.status == 200) {
      subCategory == null
        ? setData({
            ...data,
            [field]: {
              ...data[field],
              data: {
                ...data[field].data,
                [id]: { name: file.name, url: response.msg },
              },
            },
          })
        : setData({
            ...data,
            [field]: {
              ...data[field],
              data: {
                ...data[field].data,
                [subCategory]: {
                  ...data[field].data[subCategory],
                  [id]: { name: file.name, url: response.msg },
                },
              },
            },
          });
    } else {
      snackbarHandler(`Failed to upload ${file?.name || ""}!`, "error");
    }
    setFileLoading({ ...fileLoading, [id]: false });
  };

  const handleFiles = async (category, id, file) => {
    if (file.length > 0) {
      setIsFileLoading([...isFileLoading, id]);
      await handleUpload(file[0], category, id);
      let array = [...isFileLoading];
      array.pop(id);
      setIsFileLoading(array);
    }
  };

  const handleFile = async (e, dataField) => {
    if (!(e.target.files.length > 0)) {
      snackbarHandler("Failed to select file!", "error");
      return;
    }
    setIsFileLoading([...isFileLoading, dataField.fieldName]);
    await handleSingleUpload(
      e.target.files[0],
      dataField.category,
      dataField.subCategory,
      dataField.fieldName
    );
    let array = [...isFileLoading];
    array.pop(dataField.fieldName);
    setIsFileLoading(array);
  };

  const deleteFile = async (category, id) => {
    setData({
      ...data,
      [category]: {
        ...data[category],
        data: {
          ...data[category].data,
          [id]: [],
        },
      },
    });
  };

  const deleteSingleFile = async (dataField) => {
    dataField.subCategory == null
      ? setData({
          ...data,
          [dataField.category]: {
            ...data[dataField.category],
            data: {
              ...data[dataField.category].data,
              [dataField.fieldName]: null,
            },
          },
        })
      : setData({
          ...data,
          [dataField.category]: {
            ...data[dataField.category],
            data: {
              ...data[dataField.category].data,
              [dataField.subCategory]: {
                ...data[dataField.category].data[dataField.subCategory],
                [dataField.fieldName]: null,
              },
            },
          },
        });
  };
  //end of handlers related tof file in form

  //handler to validate data
  const validate = () => {
    let errorData = JSON.parse(JSON.stringify(mandatoryFields));
    let isSuccess = true;

    Object.keys(data).map((category, i) => {
      Object.keys(data[category]).map((subCategory, j) => {
        if (
          [
            // "panFile",
            // "aadhaarFile",
            // "addressFile",
            "noc",
            // "itr",
            "signature",
          ].includes(subCategory)
        ) {
          const isMandatory = mandatoryFields[category][subCategory].mandatory;
          if (isMandatory) {
            if (!data[category][subCategory]?.url) {
              errorData[category][
                subCategory
              ].error = `${subCategory} is required!`;
              isSuccess = false;
            }
          }
          return;
        }

        // if (subCategory == "photo") {
        //   const isMandatory = mandatoryFields[category][subCategory].mandatory;
        //   if (isMandatory) {
        //     if (data[category].data[subCategory]?.length != 1) {
        //       errorData[category][
        //         subCategory
        //       ].error = `${subCategory} is required!`;
        //       isSuccess = false;
        //     }
        //   }
        //   return;
        // }

        if (["founders", "teamMembers"].includes(subCategory)) {
          return;
        }
        if (typeof data[category][subCategory] == "object") {
          // if (
          //   ![
          //     "permanentAddress",
          //     "address",
          //     "isExists",
          //     "details",
          //     "items",
          //   ].includes(subCategory)
          // ) {
          //   return;
          // }
          Object.keys(data[category][subCategory]).map((field, k) => {
            if (subCategory == "items") {
              Object.keys(data[category][subCategory][field]).map(
                (itemField) => {
                  const isMandatory =
                    mandatoryFields[category][subCategory][field][itemField]
                      .mandatory;
                  if (
                    isMandatory &&
                    (!data[category][subCategory][field][itemField] ||
                      data[category][subCategory][field][itemField] == "")
                  ) {
                    errorData[category][subCategory][field][
                      itemField
                    ].error = `${itemField} is required!`;
                    isSuccess = false;
                  }
                }
              );
              return;
            }
            // if(field==="permanentAddress"){
            //   Object.keys(data[category][subCategory][field]).map(element => {
            //     const isMandatory= mandatoryFields[category][field][element].mandatory;
            //     if( isMandatory &&
            //       (!data[category][subCategory][field][element] ||
            //         data[category][subCategory][field][element] == "")){
            //           errorData[category][field][element].error = `${element} is required!`;
            //           isSuccess = false;
            //         }
            //   });
            // }

            //  if(field==="rights"){
            //   if(data[category][subCategory][field].hasRights==="yes" && mandatoryFields[category][field].hasRights.mandatory){
            //   Object.keys(data[category][subCategory][field]).map(element => {
            //     const isMandatory= mandatoryFields[category][field][element].mandatory;
            //     if( isMandatory &&
            //       (!data[category][subCategory][field][element] ||
            //         data[category][subCategory][field][element] == "")){
            //           errorData[category][field][element].error = `${element} is required!`;
            //           isSuccess = false;
            //         }
            //   });
            //  }}

            if (
              mandatoryFields[category][field] &&
              !mandatoryFields[category][field].hasOwnProperty("error")
            ) {
              Object.keys(mandatoryFields[category][field]).map((element) => {
                const isMandatory =
                  mandatoryFields[category][field][element]?.mandatory;
                if (!field === "right") {
                  if (
                    isMandatory &&
                    (!data[category][subCategory][field][element] ||
                      data[category][subCategory][field][element] == "")
                  ) {
                    errorData[category][field][
                      element
                    ].error = `${element} is required!`;
                    isSuccess = false;
                  }
                }
              });
            } else {
              const isMandatory = mandatoryFields[category][field]?.mandatory;
              if (
                isMandatory &&
                (!data[category][subCategory][field] ||
                  data[category][subCategory][field] == "")
              ) {
                errorData[category][field].error = `${field} is required!`;
                isSuccess = false;
              }
              if (field == "mobile") {
                let number = String(data[category][subCategory][field]).split(
                  " "
                );
                number.splice(0, 1);
                number = number.join("");
                if (isMandatory && !validator.isMobilePhone(number)) {
                  errorData[category][field].error = `${field} is required!`;
                  isSuccess = false;
                }
              }
              if (field == "email") {
                if (
                  !validator.isEmail(data[category][subCategory][field].trim())
                ) {
                  errorData[category][field].error = `${field} is invalid!`;
                  isSuccess = false;
                }
              }
              return;
            }
          });
        }
        // if (field == "email") {
        //   console.log("emialdsdsdfdfsdfsdfdsv");
        //   if (!validator.isEmail(data[category][subCategory].trim())) {
        //     errorData[category][
        //       subCategory
        //     ].error = `${subCategory} is invalid!`;
        //     isSuccess = false;
        //   }
        // }
        // if (subCategory == "mobile") {
        //   if (!data[category][subCategory]) {
        //     errorData[category][
        //       subCategory
        //     ].error = `${subCategory} is invalid!`;
        //     isSuccess = false;
        //     return;
        //   }
        //   let number = String(data[category][subCategory]).split(" ");
        //   number.splice(0, 1);
        //   number = number.join("");
        //   if (!validator.isMobilePhone(number)) {
        //     errorData[category][
        //       subCategory
        //     ].error = `${subCategory} is invalid!`;
        //     isSuccess = false;
        //     return;
        //   }
        // }
        if (typeof data[category][subCategory] == "string") {
          const isMandatory = mandatoryFields[category][subCategory]?.mandatory;
          if (isMandatory) {
            if (
              !data[category][subCategory] ||
              data[category][subCategory] == ""
            ) {
              errorData[category][
                subCategory
              ].error = `${subCategory} is required!`;
              isSuccess = false;
            }
          }
          return;
        }
      });
    });
    setError(errorData);
    if (isSuccess) {
      return true;
    }
    snackbarHandler("Errors in form!", "error");
    return false;
  };
  //handler to submit data to the server with api request
  const submitData = async (type) => {
    if (type == "submit" && !validate()) {
      return;
    }
    setLoading(true);
    const response = await saveFormInputs(
      {
        formData: data,
        applicationId: application._id,
        formName: "innovatorForm",
        formVersion: formVersion,
        type: type,
      },
      dispatch,
      controller
    );
    if (response && response.status == 200) {
      if (type == "submit") {
        snackbarHandler("Form Submitted..", "success");
        handleForm(null, null, true);
      } else {
        snackbarHandler("Form Saved..", "success");
        getFormDataHandler();
      }
    } else {
      snackbarHandler(response?.msg || "Something went wrong!", "error");
    }
    setLoading(false);
  };
  const modalHandler = (e, reason) => {
    if (reason == "backdropClick") return;
    setOpen(false);
  };
  const onOpenReviewDialog = () => {
    // if (reason == "backdropClick") return;
    setOpen(true);
  };
  const onCLickNextApplication = () => {
    const applicationIndex = applicationsID?.indexOf(params?.applicationId);
    if (applicationIndex === applicationsID?.length - 1) {
      navigate(`/schemes/titan/${params?.id}/${applicationsID[0]}`);
    } else {
      navigate(
        `/schemes/titan/${params?.id}/${applicationsID[applicationIndex + 1]}`
      );
    }
  };
  return (
    <>
      <PhaseContainer>
        {formLoading ? (
          <OwlLoader />
        ) : (
          <div className="row contentPanel">
            <div className="fields">
              <div className="accordionContainer">
                {activeStep === 1 && (
                  <div className="accordionDetails">
                    <div className="fieldsContainer">
                      <div className="fieldsHeader">
                        <div className="text">1. Title</div>
                        {/* <div className="status">Pending</div> */}
                      </div>
                      <div className="fieldsContents">
                        <div
                          style={{
                            display: "flex",
                            gap: "2%",
                            borderBottom: "1px solid lightgrey",
                            justifyContent: "space-between",
                            overflow: "hidden",
                            fontSize: "18px",
                            color: "var(--text4)",
                            alignItems: "center",
                          }}
                        >
                          <div className="fieldsHeader">
                            <div
                              className="text"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Titan Project ID
                            </div>
                          </div>
                          <div className="field" style={{ width: "50%" }}>
                            <Input
                              placeholder="-"
                              value={data?.title?.data?.projectId}
                              onChange={(e) =>
                                handleTextChange(
                                  "title",
                                  "title",
                                  e.target.value
                                )
                              }
                              error={!!error.title.title.error}
                              helperText={error.title.title.error}
                              disabled={!isFounder}
                              style={{
                                borderBottom: "none",
                                width: "100%",
                                padding: "0px",
                              }}
                              className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "2%",
                            borderBottom: "1px solid lightgrey",
                            justifyContent: "space-between",
                            overflow: "hidden",
                          }}
                        >
                          <div className="fieldsHeader">
                            <div
                              className="text"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Title of the Proposed Project
                            </div>
                          </div>
                          <div className="field" style={{ width: "50%" }}>
                            <Input
                              placeholder="-"
                              value={data?.title?.data?.title}
                              onChange={(e) =>
                                handleTextChange(
                                  "title",
                                  "title",
                                  e.target.value
                                )
                              }
                              error={!!error.title.title.error}
                              helperText={error.title.title.error}
                              disabled={!isFounder}
                              style={{
                                borderBottom: "none",
                                width: "100%",
                                padding: "0px",
                              }}
                              className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "2%",
                            borderBottom: "1px solid lightgrey",
                            justifyContent: "space-between",
                            overflow: "hidden",
                          }}
                        >
                          <div className="fieldsHeader">
                            <div
                              className="text"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Focus Area
                            </div>
                          </div>
                          <div className="field" style={{ width: "50%" }}>
                            <Input
                              placeholder="-"
                              value={data?.professionalDetails?.data?.focusArea}
                              onChange={(e) =>
                                handleTextChange(
                                  "title",
                                  "title",
                                  e.target.value
                                )
                              }
                              error={!!error.title.title.error}
                              helperText={error.title.title.error}
                              disabled={!isFounder}
                              style={{
                                borderBottom: "none",
                                width: "100%",
                                padding: "0px",
                              }}
                              className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "2%",
                            borderBottom: "1px solid lightgrey",
                            justifyContent: "space-between",
                            overflow: "hidden",
                          }}
                        >
                          <div className="fieldsHeader">
                            <div
                              className="text"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Sub Focus Area
                            </div>
                          </div>
                          <div className="field" style={{ width: "50%" }}>
                            <Input
                              placeholder="-"
                              value={
                                data?.professionalDetails?.data?.subFocusArea
                              }
                              onChange={(e) =>
                                handleTextChange(
                                  "title",
                                  "title",
                                  e.target.value
                                )
                              }
                              error={!!error.title.title.error}
                              helperText={error.title.title.error}
                              disabled={!isFounder}
                              style={{
                                borderBottom: "none",
                                width: "100%",
                                padding: "0px",
                              }}
                              className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "2%",
                            borderBottom: "1px solid lightgrey",
                            justifyContent: "space-between",
                            overflow: "hidden",
                          }}
                        >
                          <div className="fieldsHeader">
                            <div
                              className="text"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              SDG
                            </div>
                          </div>
                          <div className="field" style={{ width: "50%" }}>
                            <Input
                              placeholder="-"
                              value={data?.professionalDetails?.data?.sdg}
                              onChange={(e) =>
                                handleTextChange(
                                  "title",
                                  "title",
                                  e.target.value
                                )
                              }
                              error={!!error.title.title.error}
                              helperText={error.title.title.error}
                              disabled={!isFounder}
                              style={{
                                borderBottom: "none",
                                width: "100%",
                                padding: "0px",
                              }}
                              className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                            />
                          </div>
                        </div>
                        <div
                          // className="field"
                          style={{
                            display: "flex",
                            gap: "2%",
                            borderBottom: "1px solid lightgrey",
                            justifyContent: "space-between",
                            overflow: "hidden",
                          }}
                        >
                          <InputLabel sx={{ color: "var(--text4)" }}>
                            Upload your latest photo
                          </InputLabel>
                          <div className="" style={{ width: "50%" }}>
                            <PassportPhotoUpload
                              handleFile={handleFiles}
                              options={{ category: "title", id: "photo" }}
                              value={data?.title?.data?.photo}
                              deleteHandler={deleteFile}
                              loading={isFileLoading.includes("photo")}
                              error={!!error.title.photo.error}
                              helperText={error.title.photo.error}
                              disabled={!isFounder}
                            />
                          </div>
                        </div>

                        <div className="field"></div>
                      </div>
                    </div>

                    <div className="fieldsContainer">
                      <div className="fieldsHeader">
                        <div className="text">2. Innovator Details</div>
                        {/* <div className="status">Pending</div> */}
                      </div>
                      <div className="fieldsContents">
                        {user.role.roleId !== "external_reviewer" &&
                          user.role.roleId !== "founder" && (
                            <div className="fieldsContainer">
                              <div className="fieldsHeader">
                                2.1 Personal Details
                              </div>

                              <div className="fieldsContents">
                                <div className="row">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                      fontSize: "18px",
                                      color: "var(--text4)",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Titan User ID
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        value={
                                          data?.personalDetails?.data?.userId
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "title",
                                            "title",
                                            e.target.value
                                          )
                                        }
                                        error={
                                          !!error.personalDetails.name.error
                                        }
                                        helperText={
                                          error.personalDetails.name.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Name of the Innovator
                                      </div>
                                      {/* <div className="status">Pending</div> */}
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        value={
                                          data?.personalDetails?.data?.name
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "name",
                                            e.target.value
                                          )
                                        }
                                        error={
                                          !!error.personalDetails.name.error
                                        }
                                        helperText={
                                          error.personalDetails.name.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Parents name / Spouse name
                                      </div>
                                      {/* <div className="status">Pending</div> */}
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        value={
                                          data?.personalDetails?.data
                                            ?.fatherName
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "fatherName",
                                            e.target.value
                                          )
                                        }
                                        error={
                                          !!error.personalDetails.fatherName
                                            .error
                                        }
                                        helperText={
                                          error.personalDetails.fatherName.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Date of Birth
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DatePicker
                                          inputFormat="DD/MM/YYYY"
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              size="small"
                                              error={
                                                !!error.personalDetails.dob
                                                  .error
                                              }
                                              helperText={
                                                error.personalDetails.dob.error
                                              }
                                            />
                                          )}
                                          onChange={(newValue) => {
                                            if (newValue) {
                                              handleTextChange(
                                                "personalDetails",
                                                "dob",
                                                newValue.toString()
                                              );
                                            } else {
                                              handleTextChange(
                                                "personalDetails",
                                                "dob",
                                                ""
                                              );
                                            }
                                          }}
                                          value={
                                            data?.personalDetails?.data?.dob
                                          }
                                          disabled={!isFounder}
                                          style={{ color: "var(--text4)" }}
                                        />
                                      </LocalizationProvider>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Email ID
                                      </div>
                                      {/* <div className="status">Pending</div> */}
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        value={
                                          data?.personalDetails?.data?.email
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "email",
                                            e.target.value
                                          )
                                        }
                                        error={
                                          !!error.personalDetails.email.error
                                        }
                                        helperText={
                                          error.personalDetails.email.error
                                        }
                                        disabled={!isFounder}
                                        sx={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Mobile Number
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        value={
                                          data?.personalDetails?.data?.mobile
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "mobile",
                                            e.target.value
                                          )
                                        }
                                        size="small"
                                        placeholder="-"
                                        error={
                                          !!error.personalDetails.mobile.error
                                        }
                                        helperText={
                                          error.personalDetails.mobile.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Landline Number
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        value={
                                          data?.personalDetails?.data?.landline
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "landline",
                                            e.target.value
                                          )
                                        }
                                        error={
                                          !!error.personalDetails.landline.error
                                        }
                                        helperText={
                                          error.personalDetails.landline.error
                                        }
                                        disabled={!isFounder}
                                        sx={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Gender
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <FormControl fullWidth>
                                        <Select
                                          size="small"
                                          value={data?.personalDetails?.data?.gender?.toLowerCase()}
                                          onChange={(e) =>
                                            handleTextChange(
                                              "personalDetails",
                                              "gender",
                                              e.target.value
                                            )
                                          }
                                          error={
                                            !!error.personalDetails.gender.error
                                          }
                                          disabled={!isFounder}
                                        >
                                          <MenuItem value={"female"}>
                                            Female
                                          </MenuItem>
                                          <MenuItem value={"male"}>
                                            Male
                                          </MenuItem>
                                          <MenuItem value={"others"}>
                                            Others
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                      {!!error.personalDetails.gender.error ? (
                                        <FormHelperText
                                          style={
                                            !!error.personalDetails.gender.error
                                              ? {
                                                  color:
                                                    "var(--errorTextColor)",
                                                }
                                              : null
                                          }
                                        >
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          {!!error.personalDetails.gender
                                            .error ? (
                                            error.personalDetails.gender.error
                                          ) : (
                                            <>&nbsp;</>
                                          )}
                                        </FormHelperText>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Educational Qualification
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        size={"small"}
                                        value={
                                          data?.personalDetails?.data
                                            ?.qualification
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "qualification",
                                            e.target.value
                                          )
                                        }
                                        error={
                                          !!error.personalDetails.qualification
                                            .error
                                        }
                                        helperText={
                                          error.personalDetails.qualification
                                            .error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        category
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="General/OBC/SC/ST/Others"
                                        size={"small"}
                                        value={
                                          data?.personalDetails?.data?.category
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "category",
                                            e.target.value
                                          )
                                        }
                                        error={
                                          !!error.personalDetails.category.error
                                        }
                                        helperText={
                                          error.personalDetails.category.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                      width: "100%",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Pan
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        size={"small"}
                                        value={data?.personalDetails?.data?.pan}
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "pan",
                                            e.target.value
                                          )
                                        }
                                        error={
                                          !!error.personalDetails.pan.error
                                        }
                                        helperText={
                                          error.personalDetails.pan.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                      padding: "0px",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Upload Pan
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <BuildFileUploader
                                        options={{
                                          title: !!data.personalDetails.data
                                            .panFile
                                            ? "PAN File"
                                            : "",
                                        }}
                                        dataField={{
                                          fieldName: "panFile",
                                          category: "personalDetails",
                                        }}
                                        label={"PAN"}
                                        handleFile={handleFile}
                                        file={
                                          data?.personalDetails?.data?.panFile
                                        }
                                        removeFile={deleteSingleFile}
                                        error={
                                          !!error?.["personalDetails"]?.[
                                            "panFile"
                                          ]?.error
                                        }
                                        helperText={
                                          error?.["personalDetails"]?.[
                                            "panFile"
                                          ]?.error
                                        }
                                        disabled={isFounderOrReviewer}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                      width: "100%",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Aadhaar
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        size={"small"}
                                        value={
                                          data?.personalDetails?.data?.aadhar
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "aadhar",
                                            e.target.value
                                          )
                                        }
                                        error={
                                          !!error.personalDetails.aadhar.error
                                        }
                                        helperText={
                                          error.personalDetails.aadhar.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                      padding: "0px",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Upload Aadhaar
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <BuildFileUploader
                                        options={{
                                          title: !!data.personalDetails.data
                                            .aadhaarFile
                                            ? "AADHAAR File"
                                            : "",
                                        }}
                                        dataField={{
                                          fieldName: "aadhaarFile",
                                          category: "personalDetails",
                                        }}
                                        label={"Upload AADHAAR"}
                                        handleFile={handleFile}
                                        file={
                                          data?.personalDetails?.data
                                            ?.aadhaarFile
                                        }
                                        removeFile={deleteSingleFile}
                                        error={
                                          !!error?.["personalDetails"]?.[
                                            "aadhaarFile"
                                          ]?.error
                                        }
                                        helperText={
                                          error?.["personalDetails"]?.[
                                            "aadhaarFile"
                                          ]?.error
                                        }
                                        disabled={isFounderOrReviewer}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row fieldFullWidth">
                                  <div className="field">
                                    <div className="infoText">
                                      (<b>PERMANENT ADDRESS</b> : Please enclose
                                      residence certificate issued by
                                      Sub-Divisional Magistrate(DM) or a copy of
                                      ration card or any other document
                                      regarding proof of residence)
                                    </div>
                                  </div>
                                </div>

                                <div className="row fieldFullWidth">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Permanent Address
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        size="small"
                                        value={
                                          data?.personalDetails?.data
                                            ?.permanentAddress?.address
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "permanentAddress",
                                            e.target.value,
                                            "address"
                                          )
                                        }
                                        error={
                                          !!error?.["personalDetails"]?.[
                                            "permanentAddress"
                                          ]?.["address"]?.error
                                        }
                                        helperText={
                                          error?.["personalDetails"]?.[
                                            "permanentAddress"
                                          ]?.["address"]?.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row mt1">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        city
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        size="small"
                                        value={
                                          data?.personalDetails?.data
                                            ?.permanentAddress?.city
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "permanentAddress",
                                            e.target.value,
                                            "city"
                                          )
                                        }
                                        error={
                                          !!error?.["personalDetails"]?.[
                                            "permanentAddress"
                                          ]?.["city"]?.error
                                        }
                                        helperText={
                                          error?.["personalDetails"]?.[
                                            "permanentAddress"
                                          ]?.["city"]?.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        State
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        size="small"
                                        value={
                                          data?.personalDetails?.data
                                            ?.permanentAddress?.state
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "permanentAddress",
                                            e.target.value,
                                            "state"
                                          )
                                        }
                                        error={
                                          !!error?.["personalDetails"]?.[
                                            "permanentAddress"
                                          ]?.["state"]?.error
                                        }
                                        helperText={
                                          error?.["personalDetails"]?.[
                                            "permanentAddress"
                                          ]?.["state"]?.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        PinCode
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        size="small"
                                        value={
                                          data?.personalDetails?.data
                                            ?.permanentAddress?.pincode
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "permanentAddress",
                                            e.target.value,
                                            "pincode"
                                          )
                                        }
                                        error={
                                          !!error?.["personalDetails"]?.[
                                            "permanentAddress"
                                          ]?.["pincode"]?.error
                                        }
                                        helperText={
                                          error?.["personalDetails"]?.[
                                            "permanentAddress"
                                          ]?.["pincode"]?.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                      padding: "0px",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Upload Address Proof
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <BuildFileUploader
                                        options={{
                                          title: !!data.personalDetails.data
                                            ?.addressFile
                                            ? "Address Proof"
                                            : "",
                                        }}
                                        dataField={{
                                          fieldName: "addressFile",
                                          category: "personalDetails",
                                        }}
                                        label={"Address Proof"}
                                        handleFile={handleFile}
                                        file={
                                          data?.personalDetails?.data
                                            ?.addressFile
                                        }
                                        removeFile={deleteSingleFile}
                                        error={
                                          !!error?.["personalDetails"]?.[
                                            "addressFile"
                                          ]?.error
                                        }
                                        helperText={
                                          error?.["personalDetails"]?.[
                                            "addressFile"
                                          ]?.error
                                        }
                                        disabled={isFounderOrReviewer}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row fieldFullWidth">
                                  <div className="field">
                                    <div className="infoText">
                                      (<b>POSTAL ADDRESS</b> : Please enclose
                                      residence certificate issued by
                                      Sub-Divisional Magistrate(DM) or a copy of
                                      ration card or any other document
                                      regarding proof of residence)
                                    </div>
                                  </div>
                                </div>

                                {isFounder ? (
                                  <div className="row">
                                    <div className="field">
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={isCopyPostalAddress}
                                              onChange={handleCopyAddress}
                                            />
                                          }
                                          label="Copy from Permanent Address"
                                        />
                                      </FormGroup>
                                    </div>
                                  </div>
                                ) : null}

                                <div className="row fieldFullWidth">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Postal Address
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        size="small"
                                        value={
                                          data?.personalDetails?.data?.address
                                            ?.address
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "address",
                                            e.target.value,
                                            "address"
                                          )
                                        }
                                        error={
                                          !!error?.["personalDetails"]?.[
                                            "address"
                                          ]?.["address"]?.error
                                        }
                                        helperText={
                                          error?.["personalDetails"]?.[
                                            "address"
                                          ]?.["address"]?.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row mt1">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        City
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        size="small"
                                        value={
                                          data?.personalDetails?.data?.address
                                            ?.city
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "address",
                                            e.target.value,
                                            "city"
                                          )
                                        }
                                        error={
                                          !!error?.["personalDetails"]?.[
                                            "address"
                                          ]?.["city"]?.error
                                        }
                                        helperText={
                                          error?.["personalDetails"]?.[
                                            "address"
                                          ]?.["city"]?.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        State
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        size="small"
                                        value={
                                          data?.personalDetails?.data?.address
                                            ?.state
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "address",
                                            e.target.value,
                                            "state"
                                          )
                                        }
                                        error={
                                          !!error?.["personalDetails"]?.[
                                            "address"
                                          ]?.["state"]?.error
                                        }
                                        helperText={
                                          error?.["personalDetails"]?.[
                                            "address"
                                          ]?.["state"]?.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2%",
                                      borderBottom: "1px solid lightgrey",
                                      justifyContent: "space-between",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div className="fieldsHeader">
                                      <div
                                        className="text"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        PinCode
                                      </div>
                                    </div>
                                    <div
                                      className="field"
                                      style={{ width: "50%" }}
                                    >
                                      <Input
                                        placeholder="-"
                                        size="small"
                                        value={
                                          data?.personalDetails?.data?.address
                                            ?.pincode
                                        }
                                        onChange={(e) =>
                                          handleTextChange(
                                            "personalDetails",
                                            "address",
                                            e.target.value,
                                            "pincode"
                                          )
                                        }
                                        error={
                                          !!error?.["personalDetails"]?.[
                                            "address"
                                          ]?.["pincode"]?.error
                                        }
                                        helperText={
                                          error?.["personalDetails"]?.[
                                            "address"
                                          ]?.["pincode"]?.error
                                        }
                                        disabled={!isFounder}
                                        style={{
                                          borderBottom: "none",
                                          width: "100%",
                                          padding: "0px",
                                        }}
                                        className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        <div className="fieldsContainer mt1">
                          <div className="fieldsHeader">
                            2.2 Professional Details
                          </div>

                          <div className="fieldsContents">
                            <div className="row">
                              <div
                                style={{
                                  display: "flex",
                                  gap: "2%",
                                  borderBottom: "1px solid lightgrey",
                                  justifyContent: "space-between",
                                  overflow: "hidden",
                                }}
                              >
                                <div className="fieldsHeader">
                                  <div
                                    className="text"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Profession of the Innovator
                                  </div>
                                </div>
                                <div className="field" style={{ width: "50%" }}>
                                  <Input
                                    placeholder="-"
                                    size={"small"}
                                    value={
                                      data?.professionalDetails?.data
                                        ?.profession
                                    }
                                    onChange={(e) =>
                                      handleTextChange(
                                        "professionalDetails",
                                        "profession",
                                        e.target.value
                                      )
                                    }
                                    error={
                                      !!error?.["professionalDetails"]?.[
                                        "profession"
                                      ]?.error
                                    }
                                    helperText={
                                      error?.["professionalDetails"]?.[
                                        "profession"
                                      ]?.error
                                    }
                                    disabled={!isFounder}
                                    style={{
                                      borderBottom: "none",
                                      width: "100%",
                                      padding: "0px",
                                    }}
                                    className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row fieldFullWidth mt1 mb-0_8">
                              <div className="field">
                                <div className="infoText">
                                  =&gt;&nbsp;Name & Address of the Institution /
                                  Organisation (Applicable in case of Innovator
                                  in Employment / Students with any R&D
                                  organisation / Academic Institution)
                                </div>
                              </div>
                            </div>

                            <div className="row fieldFullWidth">
                              <div
                                style={{
                                  display: "flex",
                                  gap: "2%",
                                  borderBottom: "1px solid lightgrey",
                                  justifyContent: "space-between",
                                  overflow: "hidden",
                                }}
                              >
                                <div className="fieldsHeader">
                                  <div
                                    className="text"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Name & Address of the Institution /
                                    Organisation
                                  </div>
                                </div>
                                <div className="field" style={{ width: "50%" }}>
                                  <Input
                                    placeholder="-"
                                    size={"small"}
                                    value={
                                      data?.professionalDetails?.data
                                        ?.organization
                                    }
                                    onChange={(e) =>
                                      handleTextChange(
                                        "professionalDetails",
                                        "organization",
                                        e.target.value
                                      )
                                    }
                                    error={
                                      !!error?.["professionalDetails"]?.[
                                        "organization"
                                      ]?.error
                                    }
                                    helperText={
                                      error?.["professionalDetails"]?.[
                                        "organization"
                                      ]?.error
                                    }
                                    disabled={!isFounder}
                                    style={{
                                      borderBottom: "none",
                                      width: "100%",
                                      padding: "0px",
                                    }}
                                    className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row fieldFullWidth mt1 mb-0_8">
                              <div
                                style={{
                                  display: "flex",
                                  gap: "2%",
                                  borderBottom: "1px solid lightgrey",
                                  justifyContent: "space-between",
                                  overflow: "hidden",
                                  padding: "0px",
                                }}
                              >
                                <div className="field" style={{ width: "35%" }}>
                                  <div className="infoText">
                                    =&gt;&nbsp;For Innovator in Employment /
                                    Students with any R&D organisation /
                                    Academic Institution (NOC from Head of
                                    Institute / Organisation is mandatorily
                                    required)
                                  </div>
                                </div>
                                <div className="field" style={{ width: "50%" }}>
                                  <BuildFileUploader
                                    options={{
                                      title: !!data.professionalDetails.data
                                        ?.noc
                                        ? "NOC File"
                                        : "",
                                    }}
                                    dataField={{
                                      fieldName: "noc",
                                      category: "professionalDetails",
                                    }}
                                    label={"NOC"}
                                    handleFile={handleFile}
                                    file={data?.professionalDetails?.data?.noc}
                                    removeFile={deleteSingleFile}
                                    error={
                                      !!error?.["professionalDetails"]?.["noc"]
                                        ?.error
                                    }
                                    helperText={
                                      error?.["professionalDetails"]?.["noc"]
                                        ?.error
                                    }
                                    disabled={isFounderOrReviewer}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div
                                style={{
                                  display: "flex",
                                  gap: "2%",
                                  borderBottom: "1px solid lightgrey",
                                  justifyContent: "space-between",
                                  overflow: "hidden",
                                  padding: "0px",
                                }}
                              >
                                <div className="fieldsHeader">
                                  <div
                                    className="text"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Annual Income
                                  </div>
                                </div>
                                <div className="field" style={{ width: "50%" }}>
                                  <FormControl>
                                    <Input
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                      value={
                                        !data?.professionalDetails?.data
                                          ?.annualIncome
                                          ? ""
                                          : inrAddCommas(
                                              data?.professionalDetails?.data
                                                ?.annualIncome
                                            )
                                      }
                                      error={
                                        !!error?.["professionalDetails"]?.[
                                          "annualIncome"
                                        ]?.error
                                      }
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        value = value.replaceAll(",", "");
                                        value = value;
                                        handleTextChange(
                                          "professionalDetails",
                                          "annualIncome",
                                          value
                                        );
                                      }}
                                      disabled={!isFounder}
                                      style={{
                                        borderBottom: "none",
                                        width: "100%",
                                        padding: "0px",
                                      }}
                                      className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                    />
                                  </FormControl>
                                  <FormHelperText style={{ color: "red" }}>
                                    {
                                      error?.["professionalDetails"]?.[
                                        "annualIncome"
                                      ]?.error
                                    }
                                  </FormHelperText>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "2%",
                                  borderBottom: "1px solid lightgrey",
                                  alignContent: "center",
                                  justifyContent: "space-between",
                                  overflow: "hidden",
                                  padding: "0px",
                                }}
                              >
                                <div className="fieldsHeader">
                                  <div
                                    className="text"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Upload Copy of latest Income Tax Returns
                                    filed
                                  </div>
                                </div>
                                <div
                                  className="field"
                                  style={{
                                    width: "50%",
                                    alignContent: "center",
                                  }}
                                >
                                  <BuildFileUploader
                                    options={{
                                      title: !!data.professionalDetails.data.itr
                                        ? "ITR File"
                                        : "",
                                    }}
                                    dataField={{
                                      fieldName: "itr",
                                      category: "professionalDetails",
                                    }}
                                    label={
                                      "Copy of latest Income Tax Returns filed"
                                    }
                                    handleFile={handleFile}
                                    file={data?.professionalDetails?.data?.itr}
                                    removeFile={deleteSingleFile}
                                    error={
                                      !!error?.["professionalDetails"]?.["itr"]
                                        ?.error
                                    }
                                    helperText={
                                      error?.["professionalDetails"]?.["itr"]
                                        ?.error
                                    }
                                    disabled={isFounderOrReviewer}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row fieldFullWidth">
                              <div className="field">
                                <FormControl>
                                  <FormLabel
                                    id="demo-controlled-radio-buttons-group"
                                    style={{ color: "var(--text4)" }}
                                  >
                                    =&gt;&nbsp; Have you started your own
                                    venture (Startup) ? Yes / No --- If yes,
                                    Please mention the following: Name of your
                                    startup, Your role in startup (eg: CEO, COO,
                                    Product Head etc), Date of Incorporation,
                                    Revenue generated till date, - Provide Cap
                                    structure of the startup -{" "}
                                  </FormLabel>
                                  <RadioGroup
                                    value={
                                      data?.professionalDetails?.data
                                        ?.ownVenture?.isExists
                                    }
                                    onChange={(e) =>
                                      handleTextChange(
                                        "professionalDetails",
                                        "ownVenture",
                                        e.target.value,
                                        "isExists"
                                      )
                                    }
                                    row
                                    disabled={!isFounder}
                                  >
                                    <FormControlLabel
                                      value={"yes"}
                                      control={<Radio size="small" />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value={"no"}
                                      control={<Radio size="small" />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>

                                {data.professionalDetails.data.ownVenture
                                  ?.isExists == "yes" ? (
                                  <TextField
                                    multiline
                                    size="small"
                                    rows={2}
                                    placeholder="Details"
                                    value={
                                      data?.professionalDetails?.data
                                        ?.ownVenture?.details
                                    }
                                    onChange={(e) =>
                                      handleTextChange(
                                        "professionalDetails",
                                        "ownVenture",
                                        e.target.value,
                                        "details"
                                      )
                                    }
                                    error={
                                      !!error?.["professionalDetails"]?.[
                                        "ownVenture"
                                      ]?.details.error
                                    }
                                    helperText={
                                      error?.["professionalDetails"]?.[
                                        "ownVenture"
                                      ]?.details.error
                                    }
                                    disabled={!isFounder}
                                  />
                                ) : null}
                              </div>
                            </div>

                            <div className="row fieldFullWidth">
                              <div className="field">
                                <>
                                  <FormLabel className={`label`}>
                                    <div className="statusContainer"></div>
                                  </FormLabel>

                                  <TableContainer
                                    sx={{
                                      borderRadius: "12px",
                                      border: "1px solid lightgray",
                                    }}
                                  >
                                    <Table sx={{ minWidth: 650 }} size="small">
                                      <TableHead>
                                        <TableRow
                                          style={{
                                            backgroundColor: "lightgray",
                                          }}
                                        >
                                          <TableCell
                                            style={{
                                              width: "40px",
                                            }}
                                          >
                                            Sl No
                                          </TableCell>
                                          <TableCell>
                                            Name (Founder/Co-founder)
                                          </TableCell>
                                          <TableCell>Nationality</TableCell>
                                          <TableCell>
                                            Commitment (Part-time/Full-time)
                                          </TableCell>
                                          <TableCell>% Shareholding</TableCell>
                                          <TableCell>Action</TableCell>
                                        </TableRow>
                                      </TableHead>

                                      <TableBody>
                                        {data.professionalDetails.data?.founders?.map(
                                          (value, i) => {
                                            return (
                                              <TableRow key={i}>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell>
                                                  {value?.name}
                                                </TableCell>
                                                <TableCell>
                                                  {value?.nationality}
                                                </TableCell>
                                                <TableCell>
                                                  {value?.commitment}
                                                </TableCell>
                                                <TableCell>
                                                  {value?.shareholding}
                                                </TableCell>

                                                {/* {Object.keys(
																								tableFields?.professionalDetails
																									?.founders
																							)?.map((field, index) => (
																								<TableCell key={index}>
																									<TextField
																										disabled={!isFounder}
																										size="small"
																										fullWidth
																										value={
																											data?.professionalDetails
																												?.founders?.[i]?.[field]
																										}
																										onChange={(e) =>
																											handleChangeTextTable(
																												"professionalDetails",
																												"founders",
																												field,
																												e.target.value,
																												i
																											)
																										}
																									/>
																								</TableCell>
																							))} */}
                                                <TableCell>
                                                  {user?.role?.roleId !=
                                                  "founder" ? (
                                                    "-"
                                                  ) : (
                                                    <div
                                                      className="actions"
                                                      onClick={() =>
                                                        deleteRowTable(
                                                          "professionalDetails",
                                                          "founders",
                                                          i
                                                        )
                                                      }
                                                    >
                                                      {Icons.delete}
                                                    </div>
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            );
                                          }
                                        )}

                                        {/* {user?.role?.roleId != "founder" ? (
																				<></>
																			) : (
																				<TableRow>
																					<TableCell align="center" colSpan={6}>
																						<Button
																							className="muiOutlinedBtn"
																							variant="outlined"
																							onClick={() =>
																								addRowToTable(
																									"professionalDetails",
																									"founders"
																								)
																							}
																						>
																							Add Row
																						</Button>
																					</TableCell>
																				</TableRow>
																			)} */}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="fieldsContainer mt1">
                          <div className="fieldsHeader">
                            2.3 Team Member Details
                          </div>

                          <div className="fieldsContents">
                            <div className="row fieldFullWidth">
                              <div className="field">
                                <>
                                  <FormLabel className={`label`}>
                                    <div className="statusContainer"></div>
                                  </FormLabel>

                                  <TableContainer
                                    sx={{
                                      borderRadius: "12px",
                                      border: "1px solid lightgray",
                                    }}
                                  >
                                    <Table sx={{ minWidth: 650 }} size="small">
                                      <TableHead>
                                        <TableRow
                                          style={{
                                            backgroundColor: "lightgray",
                                          }}
                                        >
                                          <TableCell
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            Sl No
                                          </TableCell>
                                          <TableCell>Name</TableCell>
                                          <TableCell>AADHAAR No.</TableCell>
                                          <TableCell>PAN No.</TableCell>
                                          <TableCell>Gender</TableCell>
                                          <TableCell>Contact</TableCell>
                                          <TableCell>Email ID</TableCell>
                                          <TableCell>Qualification</TableCell>
                                          <TableCell>Action</TableCell>
                                        </TableRow>
                                      </TableHead>

                                      <TableBody>
                                        {data.teamMembers.data?.teamMembers?.map(
                                          (value, i) => {
                                            return (
                                              <TableRow key={i}>
                                                <TableCell>{i + 1}</TableCell>
                                                <TableCell>
                                                  {value?.name}
                                                </TableCell>
                                                <TableCell>
                                                  {value?.aadhaar}
                                                </TableCell>
                                                <TableCell>
                                                  {value?.pan}
                                                </TableCell>
                                                <TableCell>
                                                  {value?.gender}
                                                </TableCell>
                                                <TableCell>
                                                  {value?.contact}
                                                </TableCell>
                                                <TableCell>
                                                  {value?.email}
                                                </TableCell>
                                                <TableCell>
                                                  {value?.qualification}
                                                </TableCell>

                                                {/* {Object.keys(
																								tableFields?.teamMembers
																									?.teamMembers
																							)?.map((field, index) => (
																								<TableCell key={index}>
																									<TextField
																										size="small"
																										disabled={!isFounder}
																										fullWidth
																										value={
																											data.teamMembers
																												?.teamMembers?.[i]?.[
																												field
																											]
																										}
																										onChange={(e) =>
																											handleChangeTextTable(
																												"teamMembers",
																												"teamMembers",
																												field,
																												e.target.value,
																												i
																											)
																										}
																									/>
																								</TableCell>
																							))} */}
                                                <TableCell>
                                                  {user?.role?.roleId !=
                                                    "founder" ||
                                                  user?.role?.roleId ===
                                                    "founder" ? (
                                                    "-"
                                                  ) : (
                                                    <div
                                                      className="actions"
                                                      onClick={() =>
                                                        deleteRowTable(
                                                          "teamMembers",
                                                          "teamMembers",
                                                          i
                                                        )
                                                      }
                                                    >
                                                      {Icons.delete}
                                                    </div>
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            );
                                          }
                                        )}
                                        {/* 
                                        {user?.role?.roleId != "founder" ? (
                                          <></>
                                        ) : (
                                          <TableRow>
                                            <TableCell
                                              align="center"
                                              colSpan={9}
                                            >
                                              <Button
                                                className="muiOutlinedBtn"
                                                variant="outlined"
                                                onClick={() =>
                                                  addRowToTable(
                                                    "teamMembers",
                                                    "teamMembers"
                                                  )
                                                }
                                              >
                                                Add Row
                                              </Button>
                                            </TableCell>
                                          </TableRow>
                                        )} */}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="accordionContainer">
                {activeStep === 2 && (
                  <div className="accordionDetails">
                    <div className="fieldsContainer">
                      <div className="fieldsHeader">
                        <div className="text">3. Identify your Challenge</div>
                        {/* <div className="status">Pending</div> */}
                      </div>

                      {/* <div className="fieldsContents">
                      <div className="fieldsContainer">
                        <div className="row fieldFullWidth">
                          <div className="field">
                            <TextField
                              size="small"
                              label="Brief description of the idea highlighting innovative element"
                              value={data.description.data.opportunity}
                              onChange={(e) =>
                                handleTextChange(
                                  "description",
                                  "description",
                                  e.target.value
                                )
                              }
                              error={
                                !!error?.["description"]?.["description"]?.error
                              }
                              helperText={
                                error?.["description"]?.["description"]?.error
                              }
                              disabled={!isFounder}
                              multiline={true}
                              rows={5}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                      {/* changes1 */}
                      <div className="fieldsContents">
                        <div className="fieldsContainer">
                          <div className="row fieldFullWidth">
                            <div
                              style={{
                                display: "flex",
                                gap: "2%",
                                borderBottom: "1px solid lightgrey",
                                justifyContent: "space-between",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                className="fieldsHeader"
                                style={{ width: "30%" }}
                              >
                                <div
                                  className="text"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  Write down your opportunity statement
                                </div>
                              </div>
                              <div className="field" style={{ width: "50%" }}>
                                <TextField
                                  size="small"
                                  value={data?.description?.data?.opportunity}
                                  onChange={(e) =>
                                    handleTextChange(
                                      "description",
                                      "opportunity",
                                      e.target.value
                                    )
                                  }
                                  error={
                                    !!error?.["description"]?.["opportunity"]
                                      ?.error
                                  }
                                  helperText={
                                    error?.["description"]?.["opportunity"]
                                      ?.error
                                  }
                                  disabled={!isFounder}
                                  multiline={true}
                                  rows={5}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fieldsContents">
                        <div className="fieldsContainer">
                          <div className="row fieldFullWidth">
                            <div
                              style={{
                                display: "flex",
                                gap: "2%",
                                borderBottom: "1px solid lightgrey",
                                justifyContent: "space-between",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                className="fieldsHeader"
                                style={{ width: "30%" }}
                              >
                                <div
                                  className="text"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  What according to you is the root cause of
                                  this problem?
                                </div>
                              </div>
                              <div className="field" style={{ width: "50%" }}>
                                <TextField
                                  size="small"
                                  value={data?.description?.data?.cause}
                                  onChange={(e) =>
                                    handleTextChange(
                                      "description",
                                      "cause",
                                      e.target.value
                                    )
                                  }
                                  error={
                                    !!error?.["description"]?.["cause"]?.error
                                  }
                                  helperText={
                                    error?.["description"]?.["cause"]?.error
                                  }
                                  disabled={!isFounder}
                                  multiline={true}
                                  rows={5}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fieldsContents">
                        <div className="fieldsContainer">
                          <div className="row fieldFullWidth">
                            <div
                              style={{
                                display: "flex",
                                gap: "2%",
                                borderBottom: "1px solid lightgrey",
                                justifyContent: "space-between",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                className="fieldsHeader"
                                style={{ width: "30%" }}
                              >
                                <div
                                  className="text"
                                  style={{
                                    whiteSpace: "wrap",
                                  }}
                                >
                                  Did you go out to study the stakeholder
                                  problems firsthand before finding the root
                                  cause of the problem? If so, how did you do it
                                  and what did you find?
                                </div>
                              </div>
                              <div className="field" style={{ width: "50%" }}>
                                <TextField
                                  size="small"
                                  value={data?.description?.data?.findings}
                                  onChange={(e) =>
                                    handleTextChange(
                                      "description",
                                      "opportunity",
                                      e.target.value
                                    )
                                  }
                                  error={
                                    !!error?.["description"]?.["opportunity"]
                                      ?.error
                                  }
                                  helperText={
                                    error?.["description"]?.["opportunity"]
                                      ?.error
                                  }
                                  disabled={!isFounder}
                                  multiline={true}
                                  rows={5}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fieldsContents">
                        <div className="fieldsContainer">
                          <div className="row fieldFullWidth">
                            <div
                              style={{
                                display: "flex",
                                gap: "2%",
                                borderBottom: "1px solid lightgrey",
                                justifyContent: "space-between",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                className="fieldsHeader"
                                style={{ width: "30%" }}
                              >
                                <div
                                  className="text"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  Did you research (Secondary research) other
                                  groups or businesses facing similar problems?
                                  If yes, who are they and how are they tackling
                                  the issues?
                                </div>
                              </div>
                              <div className="field" style={{ width: "50%" }}>
                                <TextField
                                  size="small"
                                  value={
                                    data?.description?.data?.secondaryResearch
                                  }
                                  onChange={(e) =>
                                    handleTextChange(
                                      "description",
                                      "opportunity",
                                      e.target.value
                                    )
                                  }
                                  error={
                                    !!error?.["description"]?.["opportunity"]
                                      ?.error
                                  }
                                  helperText={
                                    error?.["description"]?.["opportunity"]
                                      ?.error
                                  }
                                  disabled={!isFounder}
                                  multiline={true}
                                  rows={5}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="fieldsContainer mt1">
                      <div className="fieldsHeader">
                        <div className="text">4. Showcase your Product</div>
                        {/* <div className="status">Pending</div> */}
                      </div>

                      <div className="fieldsContents">
                        <div className="fieldsContainer">
                          <div className="row fieldFullWidth">
                            <div className="field">
                              <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">
                                  a) Status of work already carried out (if any)
                                  such as. Please tick ✓ as applicable
                                </FormLabel>
                                <RadioGroup
                                  value={data?.otherDetails?.data?.statusOfWork}
                                  onChange={(e) =>
                                    handleTextChange(
                                      "otherDetails",
                                      "statusOfWork",
                                      e.target.value
                                    )
                                  }
                                  row
                                  disabled={!isFounder}
                                >
                                  <FormControlLabel
                                    value="idea"
                                    control={
                                      <Radio
                                        size="small"
                                        disabled={!isFounder}
                                      />
                                    }
                                    label="Idea level"
                                  />
                                  <FormControlLabel
                                    value="college"
                                    control={
                                      <Radio
                                        size="small"
                                        disabled={!isFounder}
                                      />
                                    }
                                    label="College Project"
                                  />
                                  <FormControlLabel
                                    value="lab"
                                    control={
                                      <Radio
                                        size="small"
                                        disabled={!isFounder}
                                      />
                                    }
                                    label="Lab level - Proof of Concept"
                                  />
                                  <FormControlLabel
                                    value="paper"
                                    control={
                                      <Radio
                                        size="small"
                                        disabled={!isFounder}
                                      />
                                    }
                                    label="Paper Presentation"
                                  />
                                  <FormControlLabel
                                    value="prototype"
                                    control={
                                      <Radio
                                        size="small"
                                        disabled={!isFounder}
                                      />
                                    }
                                    label="Working prototype ready"
                                  />
                                </RadioGroup>
                              </FormControl>

                              <FormHelperText
                                style={
                                  // !!error.personalDetails.gender.error
                                  { color: "var(--errorTextColor)" }
                                  // : null
                                }
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {!!error.otherDetails.statusOfWork.error ? (
                                  error.otherDetails.statusOfWork.error
                                ) : (
                                  <>&nbsp;</>
                                )}
                              </FormHelperText>
                            </div>
                          </div>

                          {/* <div className="row fieldFullWidth">
                          <div className="field">
                            <TextField
                              multiline
                              rows={5}
                              size="small"
                              label="b) Science and working principle behind the idea (Not more than 250 words)."
                              value={data.otherDetails.data.workingPrinciple}
                              onChange={(e) =>
                                handleTextChange(
                                  "otherDetails",
                                  "workingPrinciple",
                                  e.target.value
                                )
                              }
                              error={
                                !!error?.["otherDetails"]?.["workingPrinciple"]
                                  ?.error
                              }
                              helperText={
                                error?.["otherDetails"]?.["workingPrinciple"]
                                  ?.error
                              }
                              disabled={!isFounder}
                            />
                          </div>
                        </div>

                        <div className="row fieldFullWidth mt1">
                          <div className="field">
                            <TextField
                              multiline
                              rows={5}
                              size="small"
                              label="c) Final outcome / deliverable of the project (Not more than 250 words)."
                              value={data.otherDetails.data.outcome}
                              onChange={(e) =>
                                handleTextChange(
                                  "otherDetails",
                                  "outcome",
                                  e.target.value
                                )
                              }
                              error={
                                !!error?.["otherDetails"]?.["outcome"]?.error
                              }
                              helperText={
                                error?.["otherDetails"]?.["outcome"]?.error
                              }
                              disabled={!isFounder}
                            />
                          </div>
                        </div>

                        <div className="row fieldFullWidth mt1">
                          <div className="field">
                            <TextField
                              multiline
                              size="small"
                              label="d) Who would be beneficiary of this innovation and why ? (Not more than 200 words)."
                              value={data.otherDetails.data.beneficiary}
                              onChange={(e) =>
                                handleTextChange(
                                  "otherDetails",
                                  "beneficiary",
                                  e.target.value
                                )
                              }
                              error={
                                !!error?.["otherDetails"]?.["beneficiary"]
                                  ?.error
                              }
                              helperText={
                                error?.["otherDetails"]?.["beneficiary"]?.error
                              }
                              disabled={!isFounder}
                              rows={5}
                            />
                          </div>
                        </div> */}

                          {/* changes1
                           */}
                          <div className="row fieldFullWidth mt1">
                            <div
                              style={{
                                display: "flex",
                                gap: "2%",
                                borderBottom: "1px solid lightgrey",
                                justifyContent: "space-between",
                                overflow: "hidden",
                              }}
                            >
                              <div className="fieldsHeader">
                                <div
                                  className="text"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  b) Product Name
                                </div>
                              </div>
                              <div className="field" style={{ width: "50%" }}>
                                <Input
                                  placeholder="-"
                                  value={data?.otherDetails?.data?.productName}
                                  onChange={(e) =>
                                    handleTextChange(
                                      "title",
                                      "title",
                                      e.target.value
                                    )
                                  }
                                  error={!!error.title.title.error}
                                  helperText={error.title.title.error}
                                  disabled={!isFounder}
                                  style={{
                                    borderBottom: "none",
                                    width: "100%",
                                    padding: "0px",
                                  }}
                                  className="css-tc5fhx-MuiInputBase-root-MuiInput-root"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "2%",
                                borderBottom: "1px solid lightgrey",
                                justifyContent: "space-between",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                className="fieldsHeader"
                                style={{ width: "30%" }}
                              >
                                <div
                                  className="text"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  c) Name of your product and brief description
                                  of the product.
                                </div>
                              </div>
                              <div className="field" style={{ width: "50%" }}>
                                <TextField
                                  multiline
                                  size="small"
                                  value={data?.otherDetails?.data?.brief}
                                  onChange={(e) =>
                                    handleTextChange(
                                      "otherDetails",
                                      "brief",
                                      e.target.value
                                    )
                                  }
                                  error={
                                    !!error?.["otherDetails"]?.["brief"]?.error
                                  }
                                  helperText={
                                    error?.["otherDetails"]?.["brief"]?.error
                                  }
                                  disabled={!isFounder}
                                  rows={5}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row fieldFullWidth mt1">
                            <div
                              style={{
                                display: "flex",
                                gap: "2%",
                                borderBottom: "1px solid lightgrey",
                                justifyContent: "space-between",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                className="fieldsHeader"
                                style={{ width: "30%" }}
                              >
                                <div
                                  className="text"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  d) Write a brief elevator pitch of your
                                  product which highlights three
                                  benefits/services for the users?
                                </div>
                              </div>
                              <div className="field" style={{ width: "50%" }}>
                                <TextField
                                  multiline
                                  size="small"
                                  value={data?.otherDetails?.data?.pitch}
                                  onChange={(e) =>
                                    handleTextChange(
                                      "otherDetails",
                                      "pitch",
                                      e.target.value
                                    )
                                  }
                                  error={
                                    !!error?.["otherDetails"]?.["pitch"]?.error
                                  }
                                  helperText={
                                    error?.["otherDetails"]?.["pitch"]?.error
                                  }
                                  disabled={!isFounder}
                                  rows={5}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row fieldFullWidth mt1">
                            <div
                              style={{
                                display: "flex",
                                gap: "2%",
                                borderBottom: "1px solid lightgrey",
                                justifyContent: "space-between",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                className="fieldsHeader"
                                style={{ width: "30%" }}
                              >
                                <div
                                  className="text"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  e) What are top 3 features of the product?
                                </div>
                              </div>
                              <div className="field" style={{ width: "50%" }}>
                                <TextField
                                  multiline
                                  size="small"
                                  value={data?.otherDetails?.data?.features}
                                  onChange={(e) =>
                                    handleTextChange(
                                      "otherDetails",
                                      "features",
                                      e.target.value
                                    )
                                  }
                                  error={
                                    !!error?.["otherDetails"]?.["features"]
                                      ?.error
                                  }
                                  helperText={
                                    error?.["otherDetails"]?.["features"]?.error
                                  }
                                  disabled={!isFounder}
                                  rows={5}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row fieldFullWidth mt1">
                            <div
                              style={{
                                display: "flex",
                                gap: "2%",
                                borderBottom: "1px solid lightgrey",
                                justifyContent: "space-between",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                className="fieldsHeader"
                                style={{ width: "30%" }}
                              >
                                <div
                                  className="text"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  f) What are the biggest concerns or risks that
                                  you have related to your idea?
                                </div>
                              </div>
                              <div className="field" style={{ width: "50%" }}>
                                <TextField
                                  multiline
                                  size="small"
                                  value={data?.otherDetails?.data?.risks}
                                  onChange={(e) =>
                                    handleTextChange(
                                      "otherDetails",
                                      "risks",
                                      e.target.value
                                    )
                                  }
                                  // error={
                                  //   !!error?.["otherDetails"]?.["risks"]
                                  //     ?.error
                                  // }
                                  helperText={
                                    error?.["otherDetails"]?.["risks"]?.error
                                  }
                                  disabled={!isFounder}
                                  rows={5}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row fieldFullWidth mt1">
                            <div
                              style={{
                                display: "flex",
                                gap: "2%",
                                borderBottom: "1px solid lightgrey",
                                justifyContent: "space-between",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                className="fieldsHeader"
                                style={{ width: "30%" }}
                              >
                                <div
                                  className="text"
                                  style={{ whiteSpace: "wrap" }}
                                >
                                  g) Explain if and how your product is
                                  ecologically and environmentally sustainable?
                                </div>
                              </div>
                              <div className="field" style={{ width: "50%" }}>
                                <TextField
                                  multiline
                                  size="small"
                                  value={
                                    data?.otherDetails?.data?.sustainability
                                  }
                                  onChange={(e) =>
                                    handleTextChange(
                                      "otherDetails",
                                      "sustainability",
                                      e.target.value
                                    )
                                  }
                                  error={
                                    !!error?.["otherDetails"]?.[
                                      "sustainability"
                                    ]?.error
                                  }
                                  helperText={
                                    error?.["otherDetails"]?.["sustainability"]
                                      ?.error
                                  }
                                  disabled={!isFounder}
                                  rows={5}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="field">
                            <FormControl>
                              <FormLabel
                                id="demo-controlled-radio-buttons-group"
                                style={{ color: "var(--text4)" }}
                              >
                                h) Have you validated and tested the prototype
                                of this product with the end user? If yes, then
                                please share the results and the feedback
                                incorporated to improve the product.
                              </FormLabel>
                              <RadioGroup
                                value={
                                  data?.otherDetails?.data?.validation
                                    ?.isValidated
                                }
                                onChange={(e) =>
                                  handleTextChange(
                                    "otherDetails",
                                    "validation",
                                    e.target.value,
                                    "isValidated"
                                  )
                                }
                                row
                                disabled={!isFounder}
                              >
                                <FormControlLabel
                                  value={"yes"}
                                  control={
                                    <Radio size="small" disabled={!isFounder} />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={"no"}
                                  control={
                                    <Radio size="small" disabled={!isFounder} />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>

                            {data?.otherDetails?.data?.validation
                              ?.isValidated == "yes" ? (
                              <TextField
                                disabled={!isFounder}
                                size="small"
                                fullWidth
                                multiline
                                rows={2}
                                placeholder="Details"
                                value={
                                  data?.otherDetails?.data?.validation?.details
                                }
                                onChange={(e) =>
                                  handleTextChange(
                                    "otherDetails",
                                    "validation",
                                    e.target.value,
                                    "details"
                                  )
                                }
                              />
                            ) : null}
                          </div>
                          <div className="field">
                            <FormControl>
                              <FormLabel
                                id="demo-controlled-radio-buttons-group"
                                style={{ color: "var(--text4)" }}
                              >
                                i) Do you hold any intellectual property rights
                                over the solution design? If yes, please provide
                                details of the intellectual property rights of
                                the product design. Please upload any evidence
                                of intellectual property rights held, if any
                              </FormLabel>
                              <RadioGroup
                                value={
                                  data?.otherDetails?.data?.rights?.hasRights
                                }
                                onChange={(e) =>
                                  handleTextChange(
                                    "otherDetails",
                                    "rights",
                                    e.target.value,
                                    "hasRights"
                                  )
                                }
                                row
                                disabled={!isFounder}
                              >
                                <FormControlLabel
                                  value={"yes"}
                                  control={
                                    <Radio size="small" disabled={!isFounder} />
                                  }
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value={"no"}
                                  control={
                                    <Radio size="small" disabled={!isFounder} />
                                  }
                                  label="No"
                                />
                              </RadioGroup>
                            </FormControl>

                            {data?.otherDetails?.data?.rights?.hasRights ==
                            "yes" ? (
                              <>
                                <TextField
                                  disabled={!isFounder}
                                  size="small"
                                  fullWidth
                                  multiline
                                  rows={2}
                                  placeholder="Details"
                                  value={
                                    data?.otherDetails?.data?.rights?.details
                                  }
                                  error={
                                    !!error.otherDetails.rights.details.error
                                  }
                                  helperText={
                                    error.otherDetails.rights.details.error
                                  }
                                  onChange={(e) =>
                                    handleTextChange(
                                      "otherDetails",
                                      "rights",
                                      e.target.value,
                                      "details"
                                    )
                                  }
                                />
                                <div
                                  className="field"
                                  style={{ width: 100, marginTop: "8px" }}
                                >
                                  <BuildFileUploader
                                    options={{
                                      title: !!data?.otherDetails?.data?.rights
                                        ?.evidence
                                        ? "Attached File"
                                        : "",
                                    }}
                                    dataField={{
                                      fieldName: "evidence",
                                      subCategory: "rights",
                                      category: "otherDetails",
                                    }}
                                    label={"Upload Attachment"}
                                    handleFile={handleFile}
                                    file={
                                      data?.otherDetails?.data?.rights?.evidence
                                    }
                                    error={
                                      !!error?.otherDetails?.rights?.evidence
                                        .error
                                    }
                                    helperText={
                                      error.otherDetails.rights.evidence.error
                                    }
                                    removeFile={deleteSingleFile}
                                    disabled={isFounderOrReviewer}
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>
                          {data?.otherDetails?.data?.rights?.evidence &&
                            typeof data?.otherDetails?.data?.rights
                              ?.evidence !== "object" && (
                              <a
                                href={
                                  data?.otherDetails?.data?.rights?.evidence
                                }
                                target="__blank"
                                style={{ textDecoration: "none" }}
                              >
                                Download Supporting Doc - 1
                              </a>
                            )}
                          <div className="field">
                            <FormControl>
                              <FormLabel
                                id="demo-controlled-radio-buttons-group"
                                style={{ color: "var(--text4)" }}
                              >
                                Please upload supporting documents (formats
                                applicable - pdf, ppt, pptx, mp4, jpg)
                              </FormLabel>
                            </FormControl>

                            <div
                              className="field"
                              style={{ width: 100, marginTop: "8px" }}
                            >
                              <BuildFileUploader
                                options={{
                                  title: !!data?.otherDetails?.data?.rights
                                    ?.supportingDoc
                                    ? "Attached File"
                                    : "",
                                }}
                                dataField={{
                                  fieldName: "supportingDoc",
                                  subCategory: "rights",
                                  category: "otherDetails",
                                }}
                                label={"Upload Attachment"}
                                handleFile={handleFile}
                                file={
                                  data?.otherDetails?.data?.rights
                                    ?.supportingDoc
                                }
                                error={
                                  !!error?.otherDetails?.data?.rights
                                    ?.supportingDoc?.error
                                }
                                helperText={
                                  error?.otherDetails?.data?.rights
                                    ?.supportingDoc?.error
                                }
                                removeFile={deleteSingleFile}
                                disabled={isFounderOrReviewer}
                              />
                            </div>
                          </div>
                          {data?.otherDetails?.data?.rights?.supportingDoc &&
                            typeof data?.otherDetails?.data?.rights
                              ?.supportingDoc !== "object" && (
                              <a
                                href={
                                  data?.otherDetails?.data?.rights
                                    ?.supportingDoc
                                }
                                target="__blank"
                                style={{
                                  textDecoration: "none",
                                  color: "rgb(85, 26, 139)",
                                }}
                              >
                                Download Supporting Doc - 2
                              </a>
                            )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="fieldsContainer mt1">
                    <div className="fieldsHeader">
                      <div className="text">
                        5. Proposed costs and time frame
                      </div>
                      {/* <div className="status">Pending</div> */}
                    {/*   </div>

                    <div className="fieldsContents">
                      <div className="fieldsContainer">
                        <div className="row fieldFullWidth">
                          <div className="field">
                            <>
                              <FormLabel className={`label`}></FormLabel>

                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell width={"40px"}>
                                        Sl No
                                      </TableCell>
                                      {Object.keys(tableFields.costs.costs).map(
                                        (column, i) => {
                                          return (
                                            <TableCell key={i}>
                                              {tableFields.costs.costs[column]}
                                            </TableCell>
                                          );
                                        }
                                      )}
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {tableFields.costs.costsRows.map(
                                      (row, i) => {
                                        return (
                                          <TableRow key={i}>
                                            <TableCell>{i + 1}</TableCell>
                                            <TableCell>{row}</TableCell>
                                            {Object.keys(
                                              tableFields.costs.costs
                                            ).map((column, j) => {
                                              if (j == 0) return <></>;
                                              return (
                                                <TableCell key={j}>
                                                  <FormControl>
                                                    <OutlinedInput
                                                      size="small"
                                                      startAdornment={
                                                        <InputAdornment position="start">
                                                          ₹
                                                        </InputAdornment>
                                                      }
                                                      value={
                                                        data.costs.items[
                                                          "item" + (i + 1)
                                                        ][column] != "0"
                                                          ? inrAddCommas(
                                                              data.costs.items[
                                                                "item" + (i + 1)
                                                              ][column]
                                                            )
                                                          : "0"
                                                      }
                                                      onChange={(e) => {
                                                        let value =
                                                          e.target.value;
                                                        if (
                                                          !value ||
                                                          value == ""
                                                        )
                                                          value = "0";
                                                        value =
                                                          value.replaceAll(
                                                            ",",
                                                            ""
                                                          );
                                                        value = value;
                                                        changeStaticTableValue(
                                                          "items",
                                                          "item" +
                                                            (i + 1).toString(),
                                                          column,
                                                          value
                                                        );
                                                      }}
                                                      disabled={!isFounder}
                                                      error={
                                                        !!error?.["costs"]?.[
                                                          "items"
                                                        ]?.["item" + (i + 1)][
                                                          column
                                                        ].error
                                                      }
                                                    />
                                                  </FormControl>
                                                  <FormHelperText
                                                    style={{ color: "red" }}
                                                  >
                                                    {
                                                      error?.["costs"]?.[
                                                        "items"
                                                      ]?.["item" + (i + 1)][
                                                        column
                                                      ].error
                                                    }
                                                  </FormHelperText>
                                                </TableCell>
                                              );
                                            })}
                                          </TableRow>
                                        );
                                      }
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          </div>
                        </div>

                        <div className="row mt1">
                          <div className="field">
                            <TextField
                              size="small"
                              label="Project period in months (Not more than 12 months)"
                              value={data.costs.projectPeriod}
                              type="number"
                              onChange={(e) =>
                                handleTextChange(
                                  "costs",
                                  "projectPeriod",
                                  e.target.value
                                )
                              }
                              error={
                                !!error?.["costs"]?.["projectPeriod"]?.error
                              }
                              helperText={
                                error?.["costs"]?.["projectPeriod"]?.error
                              }
                              disabled={!isFounder}
                            />
                          </div>
                          <div className="field"></div>
                          <div className="field"></div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                )}
              </div>

              {/* <div className="accordionContainer">
              <Accordion>
                <AccordionSummary
                  expandIcon={Icons.expand}
                  aria-controls="panel1a-content"
                >
                  <Typography className="accordionHeader">
                    iii. Declarations
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="accordionDetails">
                  <div className="fieldsContainer">
                    <div className="fieldsHeader">
                      <div className="text">6. Financial Support</div>
                      {/* <div className="status">Pending</div> */}
              {/*   </div>
                    <div className="fieldsContents">
                      <div className="row fieldFullWidth">
                        <div className="field">
                          <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                              a) Have you received financial support / award for
                              your present work from any other sources? (if so,
                              please furnish details)
                            </FormLabel>
                            <RadioGroup
                              disabled={!isFounder}
                              value={
                                data.financialSupport.financialSupport.isSupport
                              }
                              onChange={(e) =>
                                handleTextChange(
                                  "financialSupport",
                                  "financialSupport",
                                  e.target.value,
                                  "isSupport"
                                )
                              }
                              row
                            >
                              <FormControlLabel
                                value={"yes"}
                                control={
                                  <Radio size="small" disabled={!isFounder} />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value={"no"}
                                control={
                                  <Radio size="small" disabled={!isFounder} />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>

                          {data.financialSupport.financialSupport.isSupport ==
                          "yes" ? (
                            <TextField
                              size="small"
                              fullWidth
                              multiline
                              rows={2}
                              placeholder="Details"
                              value={
                                data.financialSupport.financialSupport.details
                              }
                              onChange={(e) =>
                                handleTextChange(
                                  "financialSupport",
                                  "financialSupport",
                                  e.target.value,
                                  "details"
                                )
                              }
                              disabled={!isFounder}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fieldsContainer mt1">
                    <div className="fieldsHeader">
                      <div className="text">7. Other titan Centre</div>
                      {/* <div className="status">Pending</div> */}
              {/*   </div>
                    <div className="fieldsContents">
                      <div className="row fieldFullWidth">
                        <div className="field">
                          <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                              a) Have you received financial support / award for
                              your present work from any other sources? (if so,
                              please furnish details)
                            </FormLabel>
                            <RadioGroup
                              value={data.otherCenter.otherCenter.isOtherCenter}
                              onChange={(e) =>
                                handleTextChange(
                                  "otherCenter",
                                  "otherCenter",
                                  e.target.value,
                                  "isOtherCenter"
                                )
                              }
                              row
                              disabled={!isFounder}
                            >
                              <FormControlLabel
                                value={"yes"}
                                control={
                                  <Radio size="small" disabled={!isFounder} />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value={"no"}
                                control={
                                  <Radio size="small" disabled={!isFounder} />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>

                          {data.otherCenter.otherCenter.isOtherCenter ==
                          "yes" ? (
                            <TextField
                              disabled={!isFounder}
                              size="small"
                              fullWidth
                              multiline
                              rows={2}
                              placeholder="Details"
                              value={data.otherCenter.otherCenter.details}
                              onChange={(e) =>
                                handleTextChange(
                                  "otherCenter",
                                  "otherCenter",
                                  e.target.value,
                                  "details"
                                )
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fieldsContainer mt1">
                    <div className="fieldsHeader">
                      <div className="text">8. titan Received / Approved</div>
                      {/* <div className="status">Pending</div> */}
              {/*    </div>
                    <div className="fieldsContents">
                      <div className="row fieldFullWidth">
                        <div className="field">
                          <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                              a) Have you been approved / received the
                              NIDHI-titan -- Yes/ No, If Yes – Please mention
                              the name of the Centre / TBI and Date of
                              approval/Funds received
                            </FormLabel>
                            <RadioGroup
                              value={data.received.received.isReceived}
                              onChange={(e) =>
                                handleTextChange(
                                  "received",
                                  "received",
                                  e.target.value,
                                  "isReceived"
                                )
                              }
                              row
                              disabled={!isFounder}
                            >
                              <FormControlLabel
                                value={"yes"}
                                control={
                                  <Radio size="small" disabled={!isFounder} />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value={"no"}
                                control={
                                  <Radio size="small" disabled={!isFounder} />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>

                          {data.received.received.isReceived == "yes" ? (
                            <TextField
                              size="small"
                              fullWidth
                              multiline
                              rows={2}
                              placeholder="Details"
                              value={data.received.received.details}
                              onChange={(e) =>
                                handleTextChange(
                                  "received",
                                  "received",
                                  e.target.value,
                                  "details"
                                )
                              }
                              disabled={!isFounder}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fieldsContainer mt1">
                    <div className="fieldsHeader">
                      <div className="text">9. Essential Criteria</div>
                      {/* <div className="status">Pending</div> */}
              {/*     </div>
                    <div className="fieldsContents">
                      <div className="row fieldFullWidth">
                        <div className="field">
                          <>
                            <TableContainer>
                              <Table>
                                <TableBody>
                                  {Object.keys(tableFields.criteriaRows).map(
                                    (row, i) => {
                                      return (
                                        <TableRow>
                                          <TableCell width={"80%"}>
                                            {tableFields.criteriaRows[row]}
                                          </TableCell>
                                          <TableCell
                                            width={"20%"}
                                            align="center"
                                          >
                                            <RadioGroup
                                              row
                                              value={data.criteria.criteria[i]}
                                              onChange={(e) => {
                                                changeBooleanTableValue(
                                                  "criteria",
                                                  i,
                                                  e.target.value
                                                );
                                              }}
                                              disabled={!isFounder}
                                            >
                                              <FormControlLabel
                                                value={"yes"}
                                                control={
                                                  <Radio
                                                    size="small"
                                                    disabled={!isFounder}
                                                  />
                                                }
                                                label={"Yes"}
                                                disabled={
                                                  user.role.roleId != "founder"
                                                }
                                              />
                                              <FormControlLabel
                                                value={"no"}
                                                control={
                                                  <Radio
                                                    size="small"
                                                    disabled={!isFounder}
                                                  />
                                                }
                                                label={"No"}
                                                disabled={
                                                  user.role.roleId != "founder"
                                                }
                                              />
                                            </RadioGroup>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fieldsContainer mt1">
                    <div className="fieldsHeader">
                      <div className="text">10. Declaration</div>
                      {/* <div className="status">Pending</div> */}
              {/*    </div>
                    <div className="fieldsContents">
                      <div className="row fieldFullWidth">
                        <div className="field">
                          <div className="infoText">
                            I declare that all the statements made in this
                            application are true, complete and correct to the
                            best of my knowledge and belief. If any information
                            is found false or incorrect, my candidature will
                            stand cancelled and all my claims will be forfeited.
                            I have not received any financial assistance for the
                            present proposal from any other agency.
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="field">
                          <TextField
                            size="small"
                            label="Place"
                            value={data.declaration.place}
                            onChange={(e) =>
                              handleTextChange(
                                "declaration",
                                "place",
                                e.target.value
                              )
                            }
                            disabled={!isFounder}
                            error={!!error?.["declaration"]?.["place"]?.error}
                            helperText={
                              error?.["declaration"]?.["place"]?.error
                            }
                          />
                        </div>

                        <div className="field">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Date"
                              inputFormat="DD/MM/YYYY"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  error={
                                    !!error?.["declaration"]?.["date"]?.error
                                  }
                                  helperText={
                                    error?.["declaration"]?.["date"]?.error
                                  }
                                />
                              )}
                              onChange={(newValue) =>
                                handleTextChange(
                                  "declaration",
                                  "date",
                                  newValue.toString()
                                )
                              }
                              value={
                                !!data.declaration.date
                                  ? new Date().toString()
                                  : data.declaration.date
                              }
                              disabled={!isFounder}
                            />
                          </LocalizationProvider>
                        </div>

                        <div className="field">
                          <BuildFileUploader
                            options={{
                              title: !!data.declaration.signature
                                ? "Signature File"
                                : "",
                            }}
                            dataField={{
                              fieldName: "signature",
                              category: "declaration",
                            }}
                            label={"Upload Signature"}
                            handleFile={handleFile}
                            file={data.declaration.signature}
                            removeFile={deleteSingleFile}
                            error={
                              !!error?.["declaration"]?.["signature"]?.error
                            }
                            helperText={
                              error?.["declaration"]?.["signature"]?.error
                            }
                            disabled={!isFounder}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div> */}

              {from == "associate" ? (
                <></>
              ) : !loading ? (
                <div className="buttons">
                  <CustomButton
                    title="Submit Form"
                    onClickHandler={() => submitData("submit")}
                  />
                  <CustomButton
                    title="Save as Draft"
                    onClickHandler={() => submitData("draft")}
                  />
                </div>
              ) : (
                <div className="buttons">
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        )}
      </PhaseContainer>
      {activeStep === 2 && !formLoading && (
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "16px",
            gap: "8px",
          }}
        >
          <CustomButton title="EVALUATE" onClickHandler={onOpenReviewDialog} />
          <CustomButton
            title="NEXT APPLICATION"
            onClickHandler={onCLickNextApplication}
          />
        </div>
      )}
      <Dialog
        open={open}
        onClose={modalHandler}
        PaperProps={{
          style: {
            width: "100rem",
          },
        }}
        maxWidth={"100vw"}
      >
        <TablePopUp
          modalHandler={modalHandler}
          applicationsID={[]}
          selectedApplicationID={params?.applicationId}
          setSelectedApplicationID={""}
        />
      </Dialog>
    </>
  );
};

export default CompletionOfForm;

export const PhaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 1rem;

  .contentPanel {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .title {
      color: var(--text4);
      font-size: 1.2rem;
      font-weight: 400;
    }

    .closeIcon {
      cursor: pointer;
    }
  }

  .statusContainer {
    margin: 0.5rem 0;
  }

  .status {
    background: yellow;
    width: max-content;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    text-transform: capitalize;
  }

  .approved {
    background: #58d68d;
    color: #fff;
    text-transform: capitalize;
  }

  hr {
    margin: 1rem 0;
    color: var(--text4);
    width: 100%;
    opacity: 0.3;
  }

  .row {
    display: flex;
  }

  .formgroup {
    color: var(--text4);
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .accordionContainer {
      height: max-content;

      .accordionHeader {
        color: var(--text4);
      }

      .accordionDetails {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .fieldsContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex-wrap: wrap;

        .fieldsHeader {
          display: flex;
          align-items: center;
          gap: 1rem;

          .text {
            font-size: 18px;
            color: var(--text4);
          }
        }

        .fieldsContents {
          display: grid;
          row-gap: 1rem;

          .row {
            // width: 100%;
            grid-template-columns: repeat(1, minmax(300px, 1fr));
            // grid-template-columns: auto auto auto;
            display: grid;
            grid-gap: 2rem;
          }

          .fieldFullWidth {
            grid-template-columns: 1fr;
          }

          .field {
            display: flex;
            flex-direction: column;
            min-width: 300px;
            height: max-content;

            .infoText {
              color: var(--text4);
              line-height: 1.5;
            }
          }
        }
      }
    }

    .field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .inputField {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem 0;
      }

      .label {
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
        white-space: pre-wrap;
      }

      .fileUploader {
        width: 100%;
        padding: 1rem;
        border: 1px solid var(--text3);
        border-style: dashed;
        border-radius: 4px;
        min-height: 5rem;
        cursor: pointer;

        .files {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .file {
            margin-right: 1rem;
          }
        }

        .component {
          box-sizing: border-box;
          min-width: 100%;
          min-height: 5rem;
        }
      }
    }

    .addRowButton {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;

      button {
        color: var(--primary) !important;
      }
    }

    .buttons {
      display: flex;
      z-index: 99999999;
      justify-content: center;
      gap: 1rem;
      flex-wrap: wrap;
      position: fixed;
      bottom: -40px;
      padding: 20px 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      width: 100%;
    }
  }

  .tableContainer {
    background: var(--light);

    table {
      border-collapse: separate;
      border-spacing: 10px;
      table-layout: fixed;
      thead {
        tr {
          th {
            border: none;
            padding: 0 !important;
            color: var(--text4);
            font-size: 1.1rem;
            text-align: center;
            background: gray;
          }

          .tc-50 {
            width: 50px !important;
          }

          .tc-200 {
            min-width: 200px !important;
          }
        }
      }

      tbody {
        tr {
          height: 180px;
          td {
            border: none;
            color: var(--text1);
            background: #fff;
            height: 100%;

            textarea {
              width: 100%;
              height: 100%;
              height: 8rem;
              line-height: 1.4;
              color: var(--text4);
              resize: none;
              border: none;
              outline: none;
              font-size: 1rem;
            }

            .actions {
              display: flex;
              justify-content: center;
              flex-direction: column;
              gap: 1rem;
              align-items: center;

              .action {
                cursor: pointer;
                transition: 0.1s;

                &:hover {
                  color: var(--text4);
                }
              }
            }
          }

          .va-center {
            text-align: center;
          }
        }
      }
    }
  }
  input.Mui-disabled {
    -webkit-text-fill-color: #404040 !important;
  }

  textarea.Mui-disabled {
    -webkit-text-fill-color: #404040 !important;
  }
  .css-tc5fhx-MuiInputBase-root-MuiInput-root::after {
    border-bottom: none !important;
  }
  .css-tc5fhx-MuiInputBase-root-MuiInput-root::before {
    border-bottom: none !important;
  }

  // .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  // 	padding: 8px;
  // }
  .objectiveTable {
    table {
      thead {
        tr {
          // height: 6rem;
          th {
            height: auto !important;
            overflow: hidden;
            text-overflow: clip;
          }
        }
      }
    }
  }
`;

export const CommentsContainer = styled.div`
  width: ${(props) => (props.openComments ? "25rem" : "2rem")};
  transition: 0.2s width;
  display: flex;
  height: 100%;
  .arrow {
    background: var(--primary);
    height: 100%;
    min-width: 2rem;
    max-width: 2rem;
    cursor: pointer;
    border-top-left-radius: ${(props) => (props.index == 0 ? "15px" : "none")};
    border-bottom-left-radius: ${(props) =>
      props.index == 11 ? "15px" : "none"};
    svg {
      font-size: 1.7rem;
      color: #fff;
      transform: ${(props) =>
        props.openComments ? "rotate(0deg)" : "rotate(180deg)"};
      display: ${(props) => (props.index == 0 ? "flex" : "none")};
      margin-top: 0.5rem;
      justify-content: center;
      width: 100%;
    }
  }
  .commentsSection {
    width: 100%;
    height: 100%;
    border: 1px solid #d0d3d4;
    border-left: none;
    border-top: ${(props) => (props.index == 0 ? "" : "none")};
    padding: 1rem;
    display: ${(props) => (props.openComments ? "flex" : "none")};
    flex-direction: column;
    textarea {
      margin-top: 0.5rem;
      resize: none;
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      color: var(--text4);
      height: 100%;
      border: 1px solid #d0d3d4;
      outline: none;
      border-radius: 8px;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .muiBtn {
        // padding: 0rem 1.5rem;
        height: 2rem;
        color: var(--primary) !important;
      }
      .radioButtons {
        font-size: 0.9rem;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        label {
          width: max-content;
        }
      }
    }
  }
`;
