import {
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  applicationCount,
  fetchAllApplicationsData,
} from "../../../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../providers/GlobalProvider";
import { BigDispatch } from "../../../../../../providers/scheme/big/BigProvider";
import { updateBigNotification1 } from "../../../../../../reducers/schemes/big/applicantee/tabs";
import { updateBigPathNav } from "../../../../../../reducers/schemes/big/pathNav";
import BigSchemeStore from "../../../../../../store/scheme/big/big";
import { ApplicationStatus } from "../../../../../../utils/constants";
import paths from "../../../../../../utils/links";
import { Gif } from "../../../../../../utils/mediaExports";
import CustomButton from "../../../../../customComponents/CustomButton";
import CustomTextSearch from "../../../../../customComponents/CustomSearchText";
import NewApplicantModal from "../../../../customComponents/modals/NewApplicantModal";

const tabs = {
  0: "applications",
  1: "eligibility",
  2: "review",
  3: "birac",
  4: "tep",
  5: "award",
  6: "dueDiligence",
  7: "agreementSigning",
  8: "milestones",
  9: "selected",
};

const Applications = ({ call, active }) => {
  const dispatch = globalDispatch();
  const bigDispatch = BigDispatch();
  const controller = new AbortController();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [textSearch, setTextSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [applications, setApplications] = useState([]);

  const params = useParams();
  const { user } = globalSelector((state) => state.auth);

  const modalHandler = (e, reason) => {
    if (reason == "backdropClick") return;
    setOpen(false);
  };

  const getApplications = async () => {
    applicationCount({ callId: call._id }, dispatch, controller).then(
      (response) => {
        console.log(response);
        Object.keys(tabs).map((key, i) => {
          bigDispatch(
            updateBigNotification1({
              id: tabs[key],
              value: response.msg?.[i] || 0,
            })
          );
        });
      }
    );

    const response = await fetchAllApplicationsData(
      { callId: call._id, phase: active + 1 },
      dispatch,
      controller
    );

    if (response && response.status == 200) {
      bigDispatch(
        updateBigNotification1({
          id: tabs[active],
          value: response.msg?.applications?.length || 0,
        })
      );
      setApplications(response.msg?.applications);
    }
    setLoading(false);
  };

  useEffect(() => {
    getApplications();
  }, []);

  return (
    <SchemePhaseTabContainer>
      <div className="topRow">
        <CustomTextSearch
          value={textSearch}
          setValue={(value) => setTextSearch(value)}
          upcomingBanner={true}
        />
        <CustomButton
          title="Add New Applicant"
          onClickHandler={() => setOpen(true)}
        />
      </div>

      <TableContainer className="tableContainer pm_applicationsTable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width={"40px"} align="center">
                Sl No
              </TableCell>
              <TableCell>Company Name</TableCell>
              {/* <TableCell>Scheme</TableCell> */}
              <TableCell>Email ID</TableCell>
              <TableCell>Date of Application</TableCell>
              {active != 8 ? (
                <TableCell>Phase Status</TableCell>
              ) : user.role.roleId == "founder" ||
                user.role.roleId == "super_admin" ||
                user.role.roleId == "program_manager" ? (
                <TableCell align="center">Project Management</TableCell>
              ) : (
                <></>
              )}
              {/* <TableCell>Application Status</TableCell> */}
              {active != 0 && active != 8 && active != 9 ? (
                <TableCell>Comment</TableCell>
              ) : null}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="loading">
                    <img src={Gif.flying} alt="loading" />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              applications.map((application, index) => {
                return (
                  <TableRow
                    key={index}
                    className="applicationRow"
                    onClick={() =>
                      navigate(`/schemes/big/${call._id}/${application._id}`)
                    }
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell>{application.companyName}</TableCell>
                    {/* <TableCell>{application.scheme}</TableCell> */}
                    <TableCell>{application?.applicantEmail}</TableCell>
                    <TableCell>
                      {application?.createdOn
                        ? new Date(application?.createdOn).toLocaleDateString()
                        : "-"}
                    </TableCell>
                    {active != 8 ? (
                      <TableCell>
                        {ApplicationStatus[
                          application?.applicationData?.["phase" + (active + 1)]
                            ?.status
                        ] || ""}
                      </TableCell>
                    ) : user.role.roleId == "founder" ||
                      user.role.roleId == "super_admin" ||
                      user.role.roleId == "program_manager" ? (
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          className="muiOutlinedBtn"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/schemes/milestones/${application?._id}`);
                          }}
                        >
                          Project Management
                        </Button>
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {/* <TableCell>
                      <span className="statusText">
                        {ApplicationStatus[application?.status] ||
                          application?.status}
                      </span>
                    </TableCell> */}
                    {active != 0 && active != 8 && active != 9 ? (
                      <TableCell>
                        {application?.applicationData?.["phase" + (active + 1)]
                          ?.comment || "-"}
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })
            )}

            {!loading && applications.length == 0 ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="no-application">No Applications!</div>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={modalHandler}
        PaperProps={{ style: { width: "30rem" } }}
      >
        <NewApplicantModal
          modalHandler={modalHandler}
          refreshApplications={getApplications}
          call={call}
        />
      </Dialog>
    </SchemePhaseTabContainer>
  );
};

export default Applications;

export const SchemePhaseTabContainer = styled.div`
  .statusTextApplication {
    font-size: 1.1rem;
    text-transform: capitalize;
    padding: 0.5rem 1rem;
    width: max-content;
    border-radius: 8px;
  }

  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .no-application {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 1.2rem;
    color: var(--errorTextColor);
  }

  .container {
    .actions {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 2rem;
      justify-content: flex-end;

      .title {
        color: var(--text1);
      }
    }
  }

  .tableContainer {
    background: #fff;
    margin-top: 2rem;

    .loading {
      display: flex;
      justify-content: center;

      img {
        width: 10rem;
      }
    }

    table {
      thead {
        tr {
          th {
            color: var(--text1);
            font-size: 1rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .pm_applicationsTable {
    .statusText {
      text-transform: capitalize;
    }

    .applicationRow {
      cursor: pointer;

      &:hover {
        background: rgba(1, 210, 178, 0.2);
      }
    }
  }

  .dueDiligenceTable {
    .yes {
      color: var(--successTextColor);
    }

    .no {
      color: var(--errorTextColor);
    }

    .yesBg {
      background: #58e88063;
      padding: 0.4rem;
      border-radius: 7px;
      display: flex;
      width: max-content;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      svg {
        color: #2a9749;
        font-size: 1.3rem;
      }
    }

    .noBg {
      background: #fa8484b2;
      padding: 0.4rem;
      border-radius: 7px;
      display: flex;
      width: max-content;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      svg {
        color: #de0c0c;
        font-size: 1.3rem;
      }
    }

    .table {
      tbody {
        tr {
          &:nth-child(2n + 1) {
            background: var(--hoverColor);
          }

          cursor: pointer;
        }

        td {
          padding: 1rem 1rem !important;
          font-size: 1rem !important;
        }
      }

      td,
      th {
        &:nth-child(n + 2) {
          border-right: 1px solid #d0d3d4;
        }
        &:nth-child(n + 16) {
          border-right: none;
          background: #fff;
        }
      }
    }
  }

  .applicantTable {
    .statusText {
      text-transform: capitalize;
    }

    table {
      thead {
        tr {
          th {
            color: var(--text3);
            font-size: 1.1rem;
            font-weight: 400;
          }
        }
      }

      tbody {
        tr {
          td {
            color: var(--text1);
          }
        }
      }
    }

    .subtextAction {
      color: var(--text3);
      cursor: pointer;
    }

    .text {
      font-size: 1.1rem;
    }

    .actionText {
      font-size: 1.1rem;
      color: var(--primary);
      cursor: pointer;
      transition: 0.1s;

      &:hover {
        color: green;
      }
    }
  }

  .milestoneTable {
    .workplanContent {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      border: 1px solid rgba(224, 224, 224, 1);
      padding: 0.5rem;
      border-radius: 4px;

      .content {
        color: black;

        .desc {
          color: var(--text1);
        }

        textarea {
          width: 100%;
          resize: none;
          padding: 0.5rem;
          box-sizing: border-box;
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    align-items: center;
    gap: 1rem;

    .title {
      color: var(--text1);
    }
  }
`;
