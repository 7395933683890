import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Gif } from "../../../../../../../../utils/mediaExports";

const theadings = [
  {
    title: "Sl No.",
    width: "50px",
  },
  {
    title: "Titan User ID",
    width: "",
  },
  {
    title: "Name of the Applicant",
    width: "",
  },
  {
    title: "Titan Project ID",
    width: "",
  },
  {
    title: "Startup Details",
    width: "",
  },
  {
    title: "Relevance",
    width: "",
  },
  {
    title: "Effectiveness",
    width: "",
  },
  {
    title: "Impact",
    width: "",
  },
  {
    title: "Originality and Innovation",
    width: "",
  },
  {
    title: "Presentation",
    width: "",
  },

  {
    title: "Total Score",
    width: "",
  },
  {
    title: "Recommend",
    width: "",
  },
  {
    title: "Comments",
    width: "",
  },
];

const PersonTable = ({
  person,
  applications,
  callId,
  reviewers,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  pageNumber,
  applicationsCount,
  loading,
}) => {
  const navigate = useNavigate();
  const reviewerData = reviewers?.filter(
    (Data) => Data?.id === person?.id || Data?.abbr === person
  );
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {theadings?.map((heading, i) => {
              return (
                <TableCell align="center" width={heading.width} key={i}>
                  {heading.title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={13}>
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Gif.flying}
                    alt="loading"
                    style={{ width: "10rem" }}
                  />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            applications?.map((application, i) => {
              let data = "";
              if (
                application?.applicationData?.phase2?.data &&
                application?.applicationData?.phase2?.data[reviewerData[0]?.id]
              ) {
                data = Object.values(
                  application?.applicationData?.phase2?.data
                )[0];
              } else {
                data = {};
              }
              return (
                // data && (
                <TableRow key={i}>
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell align="center">
                    {application?.userId ? application?.userId : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {application?.applicantName
                      ? application?.applicantName
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {application?.projectId ? application?.projectId : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {application?.companyName}
                  </TableCell>
                  <TableCell align="center">{data?.clarity || 0}</TableCell>
                  <TableCell align="center">{data?.unmetNeed || 0}</TableCell>
                  <TableCell align="center">{data?.customerPay || 0}</TableCell>
                  <TableCell align="center">{data?.novelty || 0}</TableCell>
                  <TableCell align="center">
                    {data?.techFeasibility || 0}
                  </TableCell>
                  <TableCell align="center">{data?.total || 0}</TableCell>
                  <TableCell align="center">
                    {data?.recommend ? "Yes" : "No" || "No"}
                  </TableCell>
                  <TableCell align="center">{data?.comment || "-"}</TableCell>
                </TableRow>
              );
              // );
            })
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={pageNumber}
              count={applicationsCount || 0}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default PersonTable;
