import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import CustomButton from "./CustomButton";
import styled from "styled-components";
import { fetchApplications } from "../../data/network/api/scheme";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { ApplicationStatus } from "../../utils/constants";
const ExportExcel = ({
  excelData = null,
  fileName,
  disabled,
  excelData2,
  name = null,
  isExportAllData = null,
  dispatch,
  controller,
  isProgramManager,
}) => {
  const [downloadingReport, setDownloadingReport] = useState(false);
  const { id } = useParams();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const excelDataAddition = (response, excelApplicationData) => {
    response?.msg?.applications.map((application, index) => {
      let data = "";
      if (isProgramManager) {
        let reviewersList = "";
        const reviewersData = application?.reviewer?.map((data, index) => {
          if (index === application?.reviewer?.length) {
            reviewersList = reviewersList + `${data?.name}`;
            return `${data?.name}`;
          } else {
            reviewersList = reviewersList + `${data?.name}, `;
            return `${data?.name}, `;
          }
        });
        data = {
          "Sl No.": index + 1,
          "Title of the Project": application?.projectTitle || "-",
          "Email ID": application?.applicantEmail,
          "Date of Application": application?.createdOn
            ? new Date(application?.createdOn).toLocaleDateString()
            : "-",
          Status: ApplicationStatus[application?.status] || application?.status,
          "Name of the Applicant": application?.applicantName
            ? application?.applicantName
            : "-",
          Reviewers: reviewersList,
          "Focus Area": application?.focusArea || "-",
          "Sub Focus Area": application?.subFocusArea || "-",
          SDG: application?.sdg || "-",
        };
      } else {
        data = {
          "Sl No.": index + 1,
          "Title of the Project": application?.projectTitle || "-",
          "Email ID": application?.applicantEmail,
          "Date of Application": application?.createdOn
            ? new Date(application?.createdOn).toLocaleDateString()
            : "-",
          Status: ApplicationStatus[application?.status] || application?.status,
          "Focus Area": application?.focusArea || "-",
          "Sub Focus Area": application?.subFocusArea || "-",
          SDG: application?.sdg || "-",
        };
      }
      excelApplicationData.push(data);
    });
  };
  const exportToExcel = async () => {
    if (isExportAllData) {
      setDownloadingReport(true);
      const response = await fetchApplications(
        {
          callId: id,
          phase: 1,
          isExport: true,
        },
        dispatch,
        controller
      );
      const excelApplicationData = [];
      excelDataAddition(response, excelApplicationData);
      const ws = XLSX.utils.json_to_sheet(excelApplicationData);

      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      setDownloadingReport(false);
    } else {
      const ws = XLSX.utils.json_to_sheet(excelData);
      let ws2 = "";
      let wb = "";
      if (excelData2) {
        ws2 = XLSX.utils.json_to_sheet(excelData2);
        wb = {
          Sheets: { data: ws, "Consolidated Data": ws2 },
          SheetNames: ["data", "Consolidated Data"],
        };
      } else {
        wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      }
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  return (
    <Container>
      <CustomButton
        title={
          downloadingReport
            ? "Excel Exporting..."
            : name
            ? name
            : "Excel Export"
        }
        onClickHandler={exportToExcel}
        disabled={disabled}
      />
    </Container>
  );
};

export default ExportExcel;

const Container = styled.div``;
