import {
  Button,
  Dialog,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  applicationCount,
  fetchAllApplicationsData,
} from "../../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../../providers/GlobalProvider";
import { PrayasDispatch } from "../../../../../providers/scheme/prayas/PrayasProvider";
import { updatePrayasNotification1 } from "../../../../../reducers/schemes/prayas/tabs";
import PrayasSchemeStore from "../../../../../store/scheme/prayas/prayas";
import { ApplicationStatus } from "../../../../../utils/constants";
import { Gif, Icons } from "../../../../../utils/mediaExports";
import CustomButton from "../../../../customComponents/CustomButton";
import CustomTextSearch from "../../../../customComponents/CustomSearchText";
import NewApplicantModal from "../../../customComponents/modals/NewApplicantModal";
import {
  ArrowDropDown,
  Filter,
  Filter1TwoTone,
  Filter3,
  FilterAlt,
} from "@mui/icons-material";
import ExportExcel from "../../../../customComponents/ExportExcel";

export const tabs = {
  0: "applications",
  1: "review",
  2: "pitchingSelection",
  3: "dueDeligence",
  4: "award",
  7: "agreementSigning",
  8: "milestones",
};

const Applications = ({ call, active }) => {
  const dispatch = globalDispatch();
  const prayasDispatch = PrayasDispatch();
  const controller = new AbortController();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [textSearch, setTextSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [applications, setApplications] = useState([]);
  const [filterSelected, setFilter] = useState(0);
  const [filteredApplications, setFilterApplications] = useState([]);
  const [excelData, setExcelData] = useState([]);

  const modalHandler = (e, reason) => {
    if (reason == "backdropClick") return;
    setOpen(false);
  };

  const getApplications = async () => {
    applicationCount({ callId: call._id }, dispatch, controller).then(
      (response) => {
        Object.keys(tabs).map((key, i) => {
          prayasDispatch(
            updatePrayasNotification1({
              id: tabs[key],
              value: response.msg?.[i] || 0,
            })
          );
        });
      }
    );
    const response = await fetchAllApplicationsData(
      { callId: call._id, phase: active + 1 },
      dispatch,
      controller
    );

    if (response && response.status == 200) {
      prayasDispatch(
        updatePrayasNotification1({
          id: "applications",
          value: response.msg?.applications?.length || 0,
        })
      );
      setApplications(response.msg?.applications);
      setFilterApplications(response.msg?.applications);
      let arr = [];
      response.msg?.applications.map((application, index) => {
        let data = {
          "Sl No.": index + 1,
          "Company Name": application?.companyName,
          "Title of the Project": application?.projectTitle || "-",
          "Email ID": application?.applicantEmail,
          "Date of Application": application?.createdOn
            ? new Date(application?.createdOn).toLocaleDateString()
            : "-",
          Status: ApplicationStatus[application?.status] || application?.status,
        };
        arr.push(data);
      });
      setExcelData(arr);
    }
    setLoading(false);
  };

  useEffect(() => {
    getApplications();
  }, []);

  const getFilter = (id) => {
    switch (id) {
      case 1:
        return "invited";
      case 2:
        return "applied";
    }
  };

  useEffect(() => {
    if (filterSelected === 0) {
      setFilterApplications(applications);
      return;
    }
    let arr = [];
    applications.map((application) => {
      const filter = getFilter(filterSelected);
      if (application.status == filter) {
        arr.push(application);
      }
    });
    setFilterApplications(arr);
  }, [filterSelected]);

  return (
    <SchemePhaseTabContainer>
      <div className="topRow">
        <CustomTextSearch
          value={textSearch}
          setValue={(value) => setTextSearch(value)}
          upcomingBanner={true}
        />

        <div className="panelRightTableTop">
          <ExportExcel
            excelData={excelData}
            fileName={"PRAYAS_list_" + new Date().toLocaleDateString()}
          />
          <CustomButton
            title="Add New Applicant"
            onClickHandler={() => setOpen(true)}
          />
        </div>
      </div>

      <TableContainer className="tableContainer pm_applicationsTable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width={"50px"} align="center">
                Sl No.
              </TableCell>
              <TableCell align="" width={"350px"}>
                Company Name
              </TableCell>
              <TableCell align="" width={"500px"}>
                Title of the Project
              </TableCell>
              {/* <TableCell>Scheme</TableCell> */}
              <TableCell align="">Email ID</TableCell>
              <TableCell align="">Date of Application</TableCell>
              {active == 6 ? (
                <TableCell align="">Project Management</TableCell>
              ) : (
                <TableCell align="center">
                  Status
                  <Select
                    size="small"
                    IconComponent={FilterAlt}
                    value={filterSelected}
                    sx={{ marginLeft: "0.5rem" }}
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    <MenuItem value={1}>Invited</MenuItem>
                    <MenuItem value={2}>Applied</MenuItem>
                  </Select>
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="loading">
                    <img src={Gif.flying} alt="loading" />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              filteredApplications.map((application, index) => {
                return (
                  <TableRow
                    key={index}
                    className="applicationRow"
                    onClick={() =>
                      navigate(`/schemes/prayas/${call._id}/${application._id}`)
                    }
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="">{application.companyName}</TableCell>
                    <TableCell align="">
                      {application?.projectTitle || "-"}
                    </TableCell>
                    {/* <TableCell>{application.scheme}</TableCell> */}
                    <TableCell align="">
                      {application?.applicantEmail}
                    </TableCell>
                    <TableCell align="">
                      {application?.createdOn
                        ? new Date(application?.createdOn).toLocaleDateString()
                        : "-"}
                    </TableCell>

                    {active == 6 ? (
                      <TableCell align="">
                        <Button
                          variant="outlined"
                          className="muiOutlinedBtn"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/schemes/milestones/${application?._id}`);
                          }}
                        >
                          Project Management
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell align="center">
                        <span className="statusText">
                          {ApplicationStatus[application?.status] ||
                            application?.status}
                        </span>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            )}

            {!loading && applications.length == 0 ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="no-application">No Applications!</div>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={modalHandler}
        PaperProps={{ style: { width: "30rem" } }}
      >
        <NewApplicantModal
          modalHandler={modalHandler}
          refreshApplications={getApplications}
          call={call}
        />
      </Dialog>
    </SchemePhaseTabContainer>
  );
};

export default Applications;

export const SchemePhaseTabContainer = styled.div`
  .contactPanel {
    border: 1px solid #d0d3d4;
    padding: 1rem;
    width: max-content;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    color: var(--text3);
    margin: 0 auto;
    margin-top: 2rem;

    .text {
      font-size: 20px;
      letter-spacing: 2px;
      display: flex;
      justify-content: center;
    }

    .number {
      display: flex;
      gap: 0.5rem;
      justify-content: center;

      .numberText {
        color: var(--text1);
      }
    }
  }

  .statusTextApplication {
    font-size: 1.1rem;
    text-transform: capitalize;
    padding: 0.5rem 1rem;
    width: max-content;
    border-radius: 8px;
  }

  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    .panelRightTableTop {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }

  .no-application {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 1.2rem;
    color: var(--errorTextColor);
  }

  .apply {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }

  .container {
    .actions {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 2rem;
      justify-content: flex-end;

      .title {
        color: var(--text1);
      }
    }
  }

  .tableContainer {
    background: #fff;
    margin-top: 2rem;

    .loading {
      display: flex;
      justify-content: center;

      img {
        width: 10rem;
      }
    }

    table {
      thead {
        tr {
          th {
            color: var(--text1);
            font-size: 1rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .pm_applicationsTable {
    .statusText {
      text-transform: capitalize;
    }

    .applicationRow {
      cursor: pointer;

      &:hover {
        background: rgba(1, 210, 178, 0.2);
      }
    }
  }

  .applicantTable {
    .statusText {
      text-transform: capitalize;
    }

    table {
      thead {
        tr {
          th {
            color: var(--text3);
            font-size: 1.1rem;
            font-weight: 400;
          }
        }
      }

      tbody {
        tr {
          td {
            color: var(--text1);
          }
        }
      }
    }

    .subtextAction {
      color: var(--text3);
      cursor: pointer;
    }

    .text {
      font-size: 1.1rem;
    }

    .actionText {
      font-size: 1.1rem;
      color: var(--primary);
      cursor: pointer;
      transition: 0.1s;

      &:hover {
        color: green;
      }
    }
  }
`;
