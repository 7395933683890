import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  getReviewersListData,
  getApplicationsBySelectedReviewer,
} from "../../../../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../../providers/GlobalProvider";
import { TitanDispatch } from "../../../../../../../providers/scheme/titan/TitanProvider";
import { updateTitanNotification1 } from "../../../../../../../reducers/schemes/titan/tabs";
import CustomButton from "../../../../../../customComponents/CustomButton";
import CustomTextSearch from "../../../../../../customComponents/CustomSearchText";
import { SchemePhaseTabContainer } from "../../../../big/phases/ikp/Applications";
import { tabs } from "../../Applications";
import PersonTable from "./widgets/PersonTable";
import ReviewTab from "./widgets/ReviewTab";
import ScoreCardTable from "./widgets/ScoreCardTable";
import MultipleSelectCheckmarks from "../../../../../../customComponents/CustomMultiSelect";

import ExportScoreCardExcel from "../../../../../../customComponents/exportConsolidatedExcel";

const OnlineReview = ({ call }) => {
  const [loading, setLoading] = useState(true);
  const [activeReviewTab, setActiveReviewTab] = useState(0);
  const [applications, setApplications] = useState([]);
  const [filterApply, setFilterApply] = useState(false);
  const controller = new AbortController();
  const dispatch = globalDispatch();
  const titanDispatch = TitanDispatch();
  const user = globalSelector((state) => state.auth.user);
  const [reviewers, setReviewers] = useState([]);
  const [selectedReviewers, setSelectedReviewers] = useState([]);
  const [filteredReviewers, setFilteredReviewers] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [applicationsCount, setApplicationsCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const getApplications = async () => {
    setLoading(true);
    let response1;
    if (reviewers?.length === 0) {
      response1 = await getReviewersListData(
        { callId: call._id, phase: 1 },
        dispatch,
        controller
      );
    }

    const response = await getApplicationsBySelectedReviewer(
      {
        callId: call._id,
        phase: 1,
        reviewerId:
          reviewers?.length === 0
            ? response1?.msg[0]._id
            : reviewers?.filter(
                (reviewer) =>
                  selectedReviewers[activeReviewTab] === reviewer?.abbr
              )[0].id,
        pageNumber: 1,
        pageSize: rowsPerPage,
      },
      dispatch,
      controller
    );

    if (response && response.status == 200) {
      setApplications(response.msg?.applications);
      setApplicationsCount(response?.msg?.totalApplications);
      let reviewers = new Set();
      response?.msg?.programManagers?.map((manager, i) => {
        return reviewers.add(
          JSON.stringify({ _id: manager._id, name: manager.name })
        );
      });
      for (var i = 0; i < response?.msg?.applications?.length; i++) {
        response?.msg?.applications[i]?.reviewer?.map((reviewer, i) => {
          reviewers.add(JSON.stringify(reviewer));
        });
      }

      const sampleReveiwersData = [];
      const reveiwersData = [];
      response1?.msg?.map((data) => {
        if (sampleReveiwersData.indexOf(data?._id) === -1) {
          if (data?.name === "reviewer") {
            sampleReveiwersData.push(data?._id);
            reveiwersData.push({ abbr: data?.email, id: data?._id });
          } else {
            sampleReveiwersData.push(data?._id);
            reveiwersData.push({ abbr: data?.name, id: data?._id });
          }
        }
      });

      const nameData = reveiwersData.map((data) => data?.abbr);
      setFilteredReviewers([...nameData]);
      setReviewers([...reveiwersData]);
      setSelectedReviewers([nameData]);
    }
    setLoading(false);
  };
  useEffect(() => {
    getApplications();
  }, []);
  const getApplicationsPageWise = async () => {
    setLoading(true);
    if (
      reviewers?.length > 0 &&
      activeReviewTab !== filteredReviewers?.length
    ) {
      const response = await getApplicationsBySelectedReviewer(
        {
          callId: call._id,
          phase: 1,
          reviewerId: reviewers?.filter(
            (reviewer) => filteredReviewers[activeReviewTab] === reviewer?.abbr
          )[0].id,
          pageNumber: pageNumber + 1,
          pageSize: rowsPerPage,
        },
        dispatch,
        controller
      );
      if (response && response.status == 200) {
        titanDispatch(
          updateTitanNotification1({
            id: tabs[1],
            value: response?.msg?.totalApplications || 0,
          })
        );
        setApplications(response.msg?.applications);
        setApplicationsCount(response?.msg?.totalApplications);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    getApplicationsPageWise();
  }, [activeReviewTab, filteredReviewers, pageNumber, rowsPerPage]);
  useEffect(() => {
    if (selectedReviewers?.length > 0) {
      setFilterApply(false);
    } else {
      setFilterApply(true);
    }
  }, [selectedReviewers]);
  const ReviewTable = () => {
    if (activeReviewTab < filteredReviewers?.length) {
      return (
        <PersonTable
          person={
            selectedReviewers[activeReviewTab]
              ? selectedReviewers[activeReviewTab]
              : reviewers[activeReviewTab]
          }
          reviewers={reviewers}
          applications={applications}
          callId={call?._id}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          pageNumber={pageNumber}
          applicationsCount={applicationsCount}
          loading={loading}
        />
      );
    } else {
      return <ScoreCardTable persons={reviewers} call={call} />;
    }
  };

  const handleFilter = () => {
    const reviewerData = reviewers?.filter(
      (Data) => selectedReviewers.indexOf(Data?.abbr) !== -1
    );
    setFilteredReviewers([...selectedReviewers]);
    setFilterApply(true);
  };
  return (
    <SchemePhaseTabContainer>
      <div className="topRow">
        <CustomTextSearch
          value={searchText}
          setValue={(value) => setSearchText(value)}
          upcomingBanner={true}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <MultipleSelectCheckmarks
            Title={"reviewers"}
            setState={setSelectedReviewers}
            data={reviewers}
          />

          <CustomButton
            title={"Apply"}
            disabled={filterApply}
            onClickHandler={handleFilter}
          ></CustomButton>
        </div>

        <ExportScoreCardExcel
          reviewersData={reviewers}
          disabled={false}
          dispatch={dispatch}
          controller={controller}
          fileName={"titan_list_Score_Card" + new Date().toLocaleDateString()}
        />
      </div>
      <ReviewTab
        person={filteredReviewers}
        activeReviewTab={activeReviewTab}
        setActiveReviewTab={setActiveReviewTab}
      />
      <ReviewTabContainer>
        <ReviewTable />
      </ReviewTabContainer>
    </SchemePhaseTabContainer>
  );
};

export default OnlineReview;

const ReviewTabContainer = styled.div`
  margin-top: 1rem;
`;
